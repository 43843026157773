.sub-image{
    &--double{
        padding-top: 100px;
        display: flex;
        flex-wrap: wrap;
        @include mw($sm) {
            padding-top: 40px;
        }
        .left,
        .right{
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 528px;
            @include mw($lg) {
                height: 480px;
            }
            @include mw($sm) {
                height: 270px;
            }
        }
        .left{
            max-width: 65%;
            flex: 0 0 65%;
            @include mw($sm) {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
        .right{
            max-width: 35%;
            flex: 0 0 35%;
            @include mw($sm) {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
    }
    &--single{


        
    }
}