.sub-list{
    padding-top: 80px;
    @include mw($sm) {
        padding-top: 40px;
    }
    &__title{
        padding-bottom: 20px;
        .title{
            position: relative;
            padding-bottom: 0;
            @include mw($sm) {
                text-align: center;
            }
        }
        .close{
            cursor: pointer;
            position: absolute;
            margin: auto;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            width: 146px;
            height: 28px;
            border-radius: 5px 0;
            background-color: $navyblue;
            color: $white;
            font-family: $fontBig;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            border: 1px solid $navyblue;
            letter-spacing: 2.8px;
            padding-left: 40px;
            background-image: url(../images/hamburger.png);
            background-repeat: no-repeat;
            background-position: left 13px center;
            @include mw($md) {
                position: static;
                top: 0;
                transform: none;
                margin-top: 10px;
            }
            // @include mw($sm) {
            //     display: none !important;
            // }
            span{
                &:nth-child(2){
                    display: none;
                }
            }
            &.active{
                span{
                    &:nth-child(1){
                        display: none;
                    }
                    &:nth-child(2){
                        display: block;
                    }
                }
            }
        }
    }
    &__filters{
        // @include mw($sm) {
        //     display: none !important;
        // }
        &.active{
            display: none;
        }
        .content{
            border-top: 1px solid #b4b4b4;
            background-color: #f9f9f9;
            padding-top: 36px;
            padding-bottom: 39px;
            @include mw($lg) {
                padding-right: 36px;
                padding-left: 36px;
            }
            // .range,
            // .select,
            // .direction{
            //     @include mw($sm) {
            //         display: none !important;
            //     }
            // }
            .select{
                margin-bottom: 33px;
                height: 49px;
                .etap-select{
                    position: relative;
                    z-index: 4;
                    width: 100%;
                    height: 49px;
                    border-radius: 3px;
                    border: 1px solid #c3c3c3;
                    background-color: $white;
                    overflow: hidden;
                    .choose{
                        cursor: pointer;
                        position: absolute;
                        right: 0;
                        top: 6px;
                        margin: auto;
                        height: 37px;
                        width: 106px;
                        border-left: 1px solid #c3c3c3;
                        text-align: center;
                        color: #9a9a9a;
                        font-family: $fontBig;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 39px;
                        background-image: url(../images/selectbig.png);
                        background-position: right 18px center;
                        background-repeat: no-repeat;
                        padding-right: 20px;
                        @include mw($sm) {
                            width: 48px;
                            font-size: 0;
                        }
                    }
                    .options{
                        display: flex;
                        flex-direction: column;
                        > div{
                            font-family: $fontBig;
                            font-size: 30px;
                            line-height: 51px;
                            color: $navyblue;
                            height: 49px;
                            order: 4;
                            padding-left: 20px;
                            padding-right: 127px;
                            cursor: pointer;
                            @include mw($sm) {
                                font-size: 20px;
                                padding-left: 15px;
                                padding-right: 48px;
                            }
                            &.active{
                                order: 1;
                            }
                            &:hover:not(.active){
                                color: $blue !important;
                                span{
                                    color: $blue !important;
                                }
                            }
                        }
                    }
                    &.open{
                        height: auto;
                    }
                    select{
                        display: none;
                    }
                }
            }
            .range{
                padding-bottom: 44px;
                padding-left: 8px;
                padding-right: 8px;
                label{
                    padding-bottom: 8px;
                    display: block;
                    color: $navyblue;
                    font-family: $fontBig;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                    cursor: default;
                }
                input{
                    display: none;
                }
                .ui-slider{
                    &.ui-corner-all{
                        height: 9px;
                    }
                    .ui-widget-header{
                        background-color: $blue;
                    }
                    .ui-slider-handle{
                        top: -5px;
                        border-radius: 100%;
                        width: 18px;
                        height: 18px;
                        border: 2px solid $navyblue;
                        background-color: $white;
                        cursor: grab;
                        .tooltip{
                            width: 78px;
                            position: absolute;
                            bottom: -19px;
                            color: $black;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 14px;
                            left: 50%;
                            transform: translateX(-50%);
                            text-align: center;
                            background-color: #f9f9f9;
                        }
                    }
                }
            }
            .direction{
                padding-top: 5px;
                display: flex;
                flex-wrap: wrap;
                > span{
                    color: $navyblue;
                    font-family: $fontBig;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: center;
                    @include mw($sm) {
                        width: 100%;
                        margin-bottom: 10px;
                    }
                }
                label{
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    margin: 0 auto;
                    position: relative;
                    width: 54px;
                    height: 24px;
                    margin-left: 18px;
                    @include mw($sm) {
                        margin-left: 0;
                        width: 50%;
                        margin-bottom: 10px;
                        text-align: center;
                    }
                    input[type='checkbox']{
                        display: none;
                    }
                    > span{
                        position: relative;
                        padding-left: 30px;
                        display: block;
                        color: $black;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 24px;
                        text-transform: uppercase;
                        @include mw($sm) {
                            display: inline-block;
                            vertical-align: top;
                        }
                    } 
                    > span:before{
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                        width: 24px;
                        display: inline-block;
                        vertical-align: top;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain;
                    }
                    &.up{
                        > span:before{
                            background-image: url(../images/aup.png); 
                        }
                        input[type='checkbox']:checked + span:before{
                            background-image: url(../images/aupactive.png);   
                        }
                    }
                    &.right{
                        > span:before{
                            background-image: url(../images/aright.png); 
                        }
                        input[type='checkbox']:checked + span:before{
                            background-image: url(../images/arightactive.png);   
                         }
                    }
                    &.down{
                        > span:before{
                            background-image: url(../images/adown.png); 
                        }
                        input[type='checkbox']:checked + span:before{
                            background-image: url(../images/adownactive.png);   
                         }
                    }
                    &.left{
                        > span:before{
                            background-image: url(../images/aleft.png); 
                        }
                        input[type='checkbox']:checked + span:before{
                            background-image: url(../images/aleftactive.png);   
                         }
                    }
                }
            }
            .checkbox{
                padding-top: 20px;
                label{
                    display: block;
                    position: relative;
                    input[type='radio'],
                    input[type='checkbox']{
                        display: none;
                    }
                    > span{
                        display: block;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 14px;
                        padding-left: 24px;
                        color: $navyblue;
                        padding-top: 0;
                        a{
                            text-decoration: underline;
                        }
                    } 
                    > span:before{
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: auto;
                        display: inline-block;
                        vertical-align: top;
                        width: 15px;
                        height: 15px;
                        border: 2px solid $blue;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    input[type='radio']:checked + span:before,
                    input[type='checkbox']:checked + span:before{
                       background-image: url(../images/checkbox.jpg);   
                    }
                }
            }
            .submit{
                padding-top: 40px;
                text-align: center;
            }
        }
    }
    &__list{
        .content{
            &.overflow{
                overflow: hidden;
            }
            .loading{
                display: none;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                background-color: $white;
                z-index: 1;
                > div{
                    font-family: $fontBig;
                    color: $navyblue;
                    font-size: 20px;
                    line-height: 20px;
                    text-align: center;
                    padding-top: 30px;
                    letter-spacing: 0.6px;
                }
                &.active{
                    display: block;
                }
            }
            .table{
                position: relative;
                display: table;
                border-collapse: collapse;
                width: 100%;
                
                &--scroll{
                    @include mw($sm) {
                        margin-right: -15px;
                        overflow-x: scroll;
                        .table{
                            min-width: 410px;
                        }
                    }
                }
                .table-etap-name{
                    td{
                        display: table-cell !important;
                    }
                    .g__title{
                        text-align: center !important;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        color: $navyblue;
                    }
                }
                tr,
                > div{
                    display: table-row;
                    border-bottom: 1px solid #dedede;
                    transition: background-color .3s;
                    &.goto{
                        cursor: pointer;
                        &:hover{
                            background-color: #ececec !important;
                        }
                    }
                    &:nth-child(2n + 3){
                        background-color: #f9f9f9;
                    }
                    td,
                    > div{
                        display: table-cell;
                        text-align: center;
                        vertical-align: middle;
                        height: 73px;
                        color: #4a4a4a;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 20px;
                        padding-right: 4px;
                        &.small{
                            font-size: 13px;
                            text-transform: lowercase;
                        }
                        @include mw($sm) {
                            font-size: 12px !important;
                        }
                        @include mw(480px) {
                            font-size: 12px !important;
                        }
                        &.g__button{
                            a{
                                @include mw($lg) {
                                    font-size: 0;
                                    width: 38px;
                                    height: 38px;
                                    min-width: inherit;
                                    background-image: url(../images/glasswhite.png);
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-color: $blue !important;
                                }
                            }
                        }
                        &.g__info{
                            span, a{
                                @include mw($sm) {
                                    width: 90px;
                                    letter-spacing: 0;
                                }
                                @include mw(600px) {
                                    width: 75px;
                                    font-size: 12px;
                                }
                                @include mw(380px) {
                                    width: 64px;
                                    font-size: 10px;
                                }
                                &:not(.g__rata){
                                    margin-top: 5px;
                                    &:first-child{
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                        &.promo{
                            color: $navyblue;
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 10px;
                            @include mw($sm) {
                                font-size: 14px;
                            }
                            small{
                                display: block;
                                text-decoration: line-through;
                                color: #4a4a4a;
                            }
                        }
                        select{
                            height: 28px;
                            border: 1px solid #e1e1e1;
                            background-color: $white;
                            color: $black;
                            font-size: 13px;
                            line-height: 26px;
                            font-weight: 400;
                            padding-left: 9px;
                            padding-right: 36px;
                            background-image: url(../images/select.png);
                            background-position: right 13px center;
                            background-repeat: no-repeat;
                        }
                        .dodatki-select{
                            position: relative;
                            width: 160px;
                            margin: 0 auto;
                            .choose{
                                cursor: pointer;
                                height: 28px;
                                border: 1px solid #e1e1e1;
                                width: 100%;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                background-color: $white;
                                color: $black;
                                font-size: 13px;
                                line-height: 26px;
                                font-weight: 400;
                                padding-left: 9px;
                                padding-right: 36px;
                                background-image: url(../images/select.png);
                                background-position: right 13px center;
                                background-repeat: no-repeat;
                                text-align-last: left;
                            }
                            .options{
                                position: absolute;
                                overflow: hidden;
                                height: 0;
                                z-index: 2;
                                width: 100%;
                                > div{
                                    border: 1px solid #e1e1e1;
                                    border-top: 0;
                                    background-color: $white;
                                    padding: 6px 0;
                                }
                                .option{
                                    cursor: pointer;
                                    text-align: left;
                                    font-size: 13px;
                                    line-height: 16px;
                                    font-weight: 400;
                                    padding: 4px 9px;
                                    &.active{
                                        font-weight: 700;
                                    }
                                }
                            }
                            select{
                                display: none;
                            }
                            &.open{
                                .options{
                                    height: auto;
                                }
                            }
                        }
                        label{
                            display: block;
                            margin: 0 auto;
                            position: relative;
                            width: 14px;
                            height: 8px;
                            input[type='checkbox']{
                                display: none;
                            }
                            > span{
                                display: block;
                                font-size: 0;
                            } 
                            > span:before{
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                margin: auto;
                                display: inline-block;
                                vertical-align: top;
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-size: contain;
                            }
                            &:nth-child(1){
                                margin-bottom: 11px;
                                > span:before{
                                    background-image: url(../images/sortup.png); 
                                }
                                input[type='checkbox']:checked + span:before{
                                    background-image: url(../images/sortupactive.png);   
                                 }
                            }
                            &:nth-child(2){
                                > span:before{
                                    background-image: url(../images/sortdown.png); 
                                }
                                input[type='checkbox']:checked + span:before{
                                    background-image: url(../images/sortdownactive.png);   
                                 }
                            }
                        }
                        &:nth-child(7){
                            display: none;
                            @include mw($md) {
                                display: table-cell;
                            }
                        }
                        @include mw($md) {
                            &:nth-child(10),
                            &:nth-child(11){
                                display: none;
                            }
                        }
                        @include mw(660px) {
                            br{
                                display: block !important;
                            }
                            &:nth-child(9){
                                display: none;
                            }
                        }
                        @include mw(600px) {
                            &:nth-child(1){
                                display: none;
                            }
                            .dodatki-select {
                                width: 120px;
                                .choose{
                                    font-size: 12px;
                                }
                                .options{
                                    .option{
                                        font-size: 12px;
                                        line-height: 15px;
                                    }
                                }
                            }
                        }
                        @include mw(480px) {
                            .dodatki-select {
                                width: 84px;
                                .choose{
                                    font-size: 10px;
                                    padding-left: 5px;
                                    padding-right: 20px;
                                    background-position: right 5px center;
                                }
                                .options{
                                    .option{
                                        font-size: 10px;
                                        line-height: 14px;
                                    }
                                }
                            }
                        }
                        // @include mw(358px) {
                        //     &:nth-child(8){
                        //         display: none;
                        //     }
                        // }
                    }
                }
                .head{
                    border-top: 1px solid #b4b4b4;
                    border-bottom: 1px solid #b4b4b4;
                    br{
                        display: none;
                    }
                    td,
                    > div{
                        color: $navyblue;
                        font-family: $fontBig;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 20px;
                        padding-top: 21px;
                        padding-bottom: 18px;
                        height: auto;
                        @include mw($sm) {
                            font-size: 14px !important;
                        }
                        @include mw(660px) {
                            line-height: 16px;
                            padding-top: 15px;
                            padding-bottom: 12px;
                        }
                        @include mw(480px) {
                            font-size: 14px !important;
                            line-height: 12px;
                            padding-top: 19px;
                            padding-bottom: 16px;
                        }
                    }
                }
                .sort{
                    td,
                    > div{
                        position: relative;
                        // height: 60px;
                        padding-bottom: 40px;
                        padding-top: 15px;
                    }
                    .g__info{
                        position: absolute;
                        bottom: 0;
                        transform: translateX(-40px);
                    }
                }
                &.glue{
                    .square{
                        position: fixed;
                        bottom: auto;
                        z-index: 3;
                        top: 90px;
                    }
                }
                &.end{
                }
            }
        }
    }
    &__link{
        padding-top: 38px;
        .g__button{
            margin: -5px;
            display: flex;
            @include mw($sm) {
                flex-direction: column;
            }
            > a{
                margin: 5px;
            }
        }
        &--dev{
            padding-top: 50px;
            padding-bottom: 50px;
            @include mw($sm) {
                padding: 40px 0;
            }
        }
    }
}

// #sekcja-filters .sub-list__title {
//     @include mw($sm) {
//         display: none;
//     }
// }