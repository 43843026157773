.sub-office{
    padding-top: 85px;
    @include mw($sm) {
        padding-top: 40px;
    }
    &__content{
        .image{
            padding: 10px 0;
            img{
                vertical-align: top;
                width: 100%;
                border-radius: 30px 0;
            }
        }
    }
}