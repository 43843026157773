
$color-font:                    #171717 !default;
$color-white:                   #ffffff !default;
$font-family:                   'Montserrat', sans-serif !default; 
$popup-background:              $color-white !default;
$popup-background-outer:        rgba(255, 255, 255, 0.8) !default;
$popup-border:                  0 !default;
$popup-font:                    normal normal normal 14px/2.14 $font-family;
$color-font-string: '252525' !default;
$close-icon: "data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath fill='%23#{$color-font-string}' d='M0 28.178l28.178-28.178 3.822 3.822-28.178 28.178-3.822-3.822zM3.822 0l28.178 28.178-3.822 3.822-28.178-28.178 3.822-3.822z'%3E%3C/path%3E%3C/svg%3E%0A";

.text_popup{
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: $popup-background-outer;
    cursor: pointer;

    &.show{
      display: block;
    }
    .close{
      position: absolute;
      right: 20px;
      top: 20px;
      margin: auto;
    }

    .icon-close {
      border: 0;
      padding: 6px;

      @media (max-width: 767px) {
        display: none !important;
      }

      @include icon_area(
        $social-icon-size: 18px
      );
      @include icon($close-icon);

    }
    &__container{
      position: absolute;
      margin: auto;
      height: auto;
      max-width: 100%;
      max-height: 90vh;
      overflow: hidden;
      background-color: $popup-background;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      // padding-top: 50px;
      border: $popup-border;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      // padding: 3rem 1rem 2rem;
      width: calc(100% - 2rem);

      @include smartphone {
        // min-width: 550px;
        min-height: 220px;
        width: auto;
      }
    }

    &__content {
      max-height: 90vh;
      display: flex;
      p{
        font: $popup-font;
        letter-spacing: 2.1px;
        text-align: center;
        color: $color-font;

        &.title {
          font-weight: 700;
          text-transform: none;
        }
      }
      img {
        max-height: 90vh;
        max-width: 100%;
        display: flex;
      }
    }
  }


