
@font-face {
    font-family: 'Bebas Neue 2';
    src: url('fonts/bebasneue-bold-webfont.woff2') format('woff2'),
         url('fonts/bebasneue-bold-webfont.woff') format('woff');
    font-weight: 700;
}
@font-face {
    font-family: 'Bebas Neue 2';
    src: url('fonts/bebasneue-light-webfont.woff2') format('woff2'),
         url('fonts/bebasneue-light-webfont.woff') format('woff');
    font-weight: 300;
}
@font-face {
    font-family: 'Bebas Neue 2';
    src: url('fonts/bebasneue-regular-webfont.woff2') format('woff2'),
         url('fonts/bebasneue-regular-webfont.woff') format('woff');
    font-weight: 400;
}