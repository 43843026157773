.sub-404{
    padding: 60px 0;
    @include mw($sm) {
        padding: 0;
        padding-top: 30px;
    }
    .leaf--4 {
        left: -236px;
        top: -300px;
    }
    &__image{
        position: relative;
        @include mw($sm) {
            padding-top: 30px;
        }
        img{
            width: 100%;
            vertical-align: top;
            border-radius: 0 30px;
        }
    }
    &__content{
        .title{
            color: $blue;
            font-family: $fontBig;
            font-size: 60px;
            line-height: 60px;
            padding-bottom: 20px;
            @include mw($sm) {
                padding-bottom: 1px;
            }
        }
        .smalltitle{
            color: #062f5f;
            font-family: $fontBig;
            font-size: 32px;
            line-height: 38px;
            padding-bottom: 25px;
            @include mw($sm) {
                padding-bottom: 15px;
            }
        }
        .text{
            line-height: 26px;
            padding-bottom: 35px;
            a{
                text-decoration: underline;
            }
        }
    }
}

