.sub-flat{
    padding-top: 15px;
    &__note{
        color: $black;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }
    &__link{
        text-align: center;
        padding-top: 8px;
    }
    &__content{
        position: relative;
        padding: 0 60px;
        border-radius: 30px 0;
        border: 1px solid #c8c8c8;
        background-color: #ffffff;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include mw($md) {
            padding: 0 20px;
        }
        .top{
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0;
            border-bottom: 1px solid #c8c8c8;
            max-width: 100%;
            flex: 0 0 100%;
            justify-content: space-between;

            padding-left: 70px;
            @include mw($lg) {
                padding-left: 0;
            }

            &__title{
                font-family: $fontBig2;
                font-weight: 400;
                font-size: 32px;
                line-height: 42px;
                color: $blue;
                margin-right: 10px;    
                @include mw($sm) {
                    font-size: 28px;
                    line-height: 28px;
                    strong{
                        display: block;
                    }
                }
                .stage{
                    position: absolute;
                    top: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    @include mw($md) {
                        position: static;
                    }
                }
            }
            &__zoom{
                a{
                    position: relative;
                    display: block;
                    width: 40px;
                    height: 40px;
                    border: 1px solid $blue;
                    background-color: $blue;
                    border-radius: 10px 0;
                    transition: background-color .3s;
                    &:before,
                    &:after{
                        content: '';
                        position: absolute;
                        display: block;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        background-repeat: no-repeat;
                        background-position: center;
                        transition: opacity .3s;
                    }
                    &:before{
                        background-image: url(../images/glasswhite.png);
                        opacity: 1;
                    }
                    &:after{
                        background-image: url(../images/glasscolor.png);
                        opacity: 0;
                    }
             
                    &:hover{
                        background-color: $white;
                        &:before{
                            opacity: 0;
                        }
                        &:after{
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .top-head{
            width: 100%;
            padding-top: 25px;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            color: #949494;
            font-family: $fontBig;
            span{
                color: $black;
            }
        }
        .center-gallery{
            padding-top: 25px;
            width: 100%;
            display: flex;
            @include mw($lg) {
                flex-wrap: wrap;
            }
            .gallery-image > img{
                vertical-align: top;
                width: 100%;
            }
            .slick-slide{
                border: 0;
            }
            .slider__slide{
                vertical-align: top;   
            }
            &__left{
                width: calc(100% - 174px);
                padding-right: 36px;
                @include mw($lg) {
                    width: 100%;
                    padding-right: 0;
                }
            }
            &__right{
                width: 174px;
                @include mw($lg) {
                    width: 100%;
                    padding-top: 10px;
                    // > div{
                    //     padding-left: 30px;
                    //     padding-right: 30px;
                    // }
                }
                .thumbs{
                    @include mw($lg) {
                        display: flex;
                        justify-content: center;
                    }
                    &__item{
                        margin-bottom: 15px;
                        cursor: pointer;
                        @include mw($lg) {
                            margin-bottom: 0;
                            margin-left: 5px;
                            margin-right: 5px;
                        }
                        img{
                            vertical-align: top;
                        }
                        &.active{
                            position: relative;
                            &:before{
                                content: '';
                                display: block;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                margin: auto;
                                border: 4px solid $blue;
                            }
                        }
                    }



                }
                .link{
                    text-align: center;
                    @include mw($lg) {
                        padding-top: 15px;
                    }
                }
                // .slider__slide{
                //     margin-bottom: 15px;
                //     @include mw($lg) {
                //         margin-bottom: 0;
                //         padding-left: 5px;
                //         padding-right: 5px;
                //     }
                //     img{
                //         transition: all .5s;
                //         cursor: pointer;
                //     }
                // }
                // .slick-current{
                //     .slider__slide{
                //         position: relative;
                //         img{
                //             opacity: 0.3;
                //         }
                //         &:before{
                //             content: '';
                //             display: block;
                //             position: absolute;
                //             top: 0;
                //             bottom: 0;
                //             left: 0;
                //             right: 0;
                //             margin: auto;
                //             border: 4px solid $blue;
                //             @include mw($lg) {
                //                 left: 5px;
                //                 right: 5px;
                //             }
                //         }
                //     }
                // }
                // .slick-arrow{
                //     background-color: transparent !important;
                //     border: 0 !important;
                //     font-size: 0 !important;
                //     height: 30px;
                //     width: 100%;
                //     background-position: center;
                //     background-repeat: no-repeat;
                //     background-size: 26px;
                //     @include mw($lg) {
                //         position: absolute;
                //         width: 30px;
                //         top: 50%;
                //         transform: translateY(-50%);
                //     }
                //     &.slick-prev{
                //         background-image: url(../images/arrow-up.svg);
                //         @include mw($lg) {
                //             left: -30px;
                //             background-image: url(../images/arrow-left.svg);
                //         }
                //     }
                //     &.slick-next{
                //         background-image: url(../images/arrow-down.svg);
                //         @include mw($lg) {
                //             right: -30px;
                //             background-image: url(../images/arrow-right.svg);
                //         }
                //     }
                // }
            }
        }
        .icons-center{
            padding-top: 60px;
            width: 100%;

        }
        .table-left{
            padding-top: 30px;
            width: 33%;
            @include mw($lg) {
                width: 100%;
            }
        }
        .table-right{
            padding-top: 30px;
            width: 67%;
            padding-left: 60px;
            @include mw($lg) {
                width: 100%;
                padding-left: 0;
                padding-top: 0;
            }
        }
        .left{
            padding-top: 20px;
            max-width: auto;
            flex: 0 0 auto;
            @include mw($md) {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
        .table{
            // width: 350px;
            // @include mw($md) {
                width: 100%;
            // }
            small{
                font-size: 12px;
                line-height: 14px;
            }
            > div{
                padding: 8px 0;
                border-bottom: 1px solid #f2f2f2;
                display: flex;
                align-items: center;
                &:last-child{
                    border-bottom: none;
                }
                > div{
                    &:nth-child(1){
                        max-width: 60%;
                        flex: 0 0 60%;
                        color: $black;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 18px;
                        @include mw($md) {
                            max-width: 50%;
                            flex: 0 0 50%
                        }
                    }
                    &:nth-child(2){
                        max-width: 40%;
                        flex: 0 0 40%;
                        color: $black;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 18px;
                        @include mw($md) {
                            max-width: 50%;
                            flex: 0 0 50%
                        }
                    }
                }
            }
            .g__info{
                margin-right: 14px;
            }
            .nopad{
                padding: 0 !important;
            }
            .noborder{
                border-bottom: none !important;
            }
        }
        .right{
            padding-top: 38px;
            max-width: auto;
            flex: 0 0 auto;
            @include mw($md) {
                max-width: 100%;
                flex: 0 0 100%;
            }
            .image{
                img{
                    vertical-align: top;
                    @include mw($lg) {
                        width: 430px;
                    }
                    @include mw($md) {
                        width: 100%;
                    }
                }
            }
        }
        .bottom{
            padding: 80px 0 25px;
            padding-right: 140px;
            max-width: 100%;
            flex: 0 0 100%;
            @include mw($md) {
                padding-right: 0;
            }
            .link{
                margin: 0 -10px;
                display: flex;
                flex-wrap: wrap;
                @include mw($md) {
                    justify-content: center;
                }
                a{
                    margin-top: 15px;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
        .voucher{
            position: absolute;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 139px;
            height: 182px;
            border-radius: 10px 0 28px;
            background-color: #84ca94;
            text-align: center;
            @include mw($sm) {
                position: static;
                width: auto;
                margin: 0 -20px;
                height: auto;
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                justify-content: center;
                padding: 20px;
            }
            p{
                color: $white;
                font-family: $fontBig;
                font-size: 14px;
                letter-spacing: 0.7px;
                line-height: 15px;
                text-align: center;
                margin-top: 20px;
                
                @include mw($sm) {
                    margin-top: 0;
                    br{
                        display: none;
                    }
                }
            }
            span{
                font-size: 23px;
                line-height: 24px;
                letter-spacing: 1.15px;
            }
            img{
                margin: 8px 0 11px;
                vertical-align: top;
            }
            a{
                display: inline-block;
                vertical-align: top;
                padding: 0;
                text-transform: uppercase;
                background-color: transparent;
                font-family: $fontBig;
                color: $white;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.9px;
                width: 94px;
                height: 20px;
                border-radius: 5px 0;
                border: 1px solid $white;
                text-align: center;
                transition: background-color .3s, color .3s;
                &:hover{
                    background-color: $white;
                    color: #84ca94;
                }
            }
            &--side{
                display: flex;
                align-items: center;
                width: 340px;
                height: 96px;
                padding: 0 15px;
                @include mw($md) {
                    position: static;
                    width: auto;
                    margin: 0 -20px;
                    height: auto;
                    display: flex;
                    flex: 1 1 auto;
                    flex-direction: column;
                    justify-content: center;
                    padding: 20px;
                }
                > div{
                    padding: 0 15px;
                }
                img{
                    width: 80px;
                    margin: 0;
                }
                p{
                    margin: 0;
                    text-align: left;
                    font-size: 14px;
                    @include mw($md) {
                        text-align: center;
                        margin-top: 15px;
                    }
                    span{
                        font-size: 23px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    &__iconSliderOuterContainer {
        border-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
    &__iconSlider {
        padding-left: 5%;
        padding-right: 5%;
        display: block;
        overflow: hidden;
        border-bottom: 0;
        padding-bottom: 2rem !important;
        @include mw($lg) {
            padding-left: 0;
            padding-right: 0;
        }

        @include mw($sm) {
            padding-left: 0;
            padding-right: 0;
            margin-left: -10px;
            margin-right: -10px;
            padding-bottom: 0 !important;
        }
        .slick-prev {
            left: -4% !important;
        }
        .slick-next {
            right: -4% !important;
        }
    }
    &__iconSliderRow {
        // display: flex;
        // flex-wrap: wrap;
        display: flex;

        @include mw($lg) {
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
        }
        @include mw($sm) {
            margin-left: -5px;
            margin-right: -5px;
        }
    }
    &__iconSliderElement {
        padding: 15px;
        display: flex;
        justify-content: center;
        @include mw($lg) {
            padding: 10px;
            width: 33.3%;
        }
        @include mw($sm) {
            padding: 5px;
        }
        // overflow: hidden; 
        // max-width: calc(100% / 6);
    }
    // &__iconSliderElementContent {
    //     font-family: $fontBig;
    //     font-size: 16px;
    //     line-height: 1.2;
    //     color: #284b75;
    //     padding: 12px;
    //     text-align: center;
    //     text-transform: uppercase;
    //     border: 1px solid #c8c8c8;
    //     border-radius: 35px 0;
    //     min-height: 156px;
    //     img {
    //         // padding-bottom: 15px;
    //         max-width: 100px;
    //         height: 90px;
    //         padding-bottom: 15px;
    //         margin: 0 auto;
    //     }
    // }
    .sectionTable{
        width: 100%;
    }
}