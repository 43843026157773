.sub-map{
    padding-top: 20px;
    // padding-top: 100px;
    &__top{
        .desktop{
            @include mw($sm) {
                display: none;
            }
        }
        .mobile{
            display: none;
            @include mw($sm) {
                display: block;
            }
        }
        .menu{
            padding-bottom: 20px;
            ul{
                display: flex;
                justify-content: center;
                @include mw($sm) {
                    flex-direction: column;
                    align-items: center;
                }
                li{
                    margin: 10px 0;
                    position: relative;
                    margin-right: 46px;
                    color: $navyblue;
                    font-family: $fontBig;
                    font-size: 32px;
                    font-weight: 400;
                    line-height: 36px;
                    transition: color .3s;
                    @include mw($sm) {
                        margin-right: 0;
                        &:nth-child(1){
                            display: none;
                        }
                    }
                    &:after{
                        content: '';
                        height: 0;
                        bottom: 0;
                        margin: auto;
                        position: absolute;
                        left: 0;
                        right: 0;
                        background-color: $blue;
                    }
                    &.active{
                        color: $blue;
                        &:after{
                            height: 2px;
                        }
                    }
                    &:hover{
                        color: $blue;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        .mobile-plan{
            position: relative;
            img{
                vertical-align: top;
                width: 100%;
            }
            .plan-circle{
                border: 2px solid $white;
                display: block;
                width: 6vw;
                height: 6vw;
                border-radius: 100%;
                color: $white;
                text-align: center;
                line-height: 6vw;
                font-family: $fontBig;
                font-size: 4vw;
                // opacity: .5;
                opacity: .75;
                transform: translate(-50%, -50%);
                transition: all .3s;
                // filter: grayscale(1);
                cursor: pointer;
                &.status2{
                    opacity: 1;
                    background-color: #0373bb !important;
                    filter: grayscale(0) !important;
                }
                // &.status0{
                //     opacity: .5 !important;
                // }
                // &:not(.status2):not(.active){
                //     background-color: #b7b7b7 !important;
                // }
               
                &.status3{
                    background-color: #b7b7b7 !important;
                }
                &.status0{
                    background-color: #59bcb4 !important;
                }

                &.active{
                    //filter: grayscale(0) !important;
                    background-color: #2fae38 !important;
                    opacity: 1 !important;
                }
            }
            .plan-desc{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 3px;
                text-align: center;
                > span{
                    display: inline-block;
                    vertical-align: top;
                    background-color: $white;
                    font-family: $fontBig;
                    font-size: 20px;
                    line-height: 28px;
                    color: $blue;
                    padding: 0 15px;
                    border-radius: 0 5px;
                    color: #0097cc !important;
                }
            }
        }
        .map{
            position: relative;
            margin: 0 -15px;
            &__sign{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 22px;
                margin: auto;
                @include mw($lg) {
                    bottom: 5px
                }
                @include mw($sm) {
                    padding-top: 5px;
                    position: static;
                }
                .content{
                    color: $white;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    @include mw($sm) {
                        font-size: 10px;
                        line-height: 10px;
                        color: #000;
                        br{
                            display: none;
                        }
                    }
                }
            }
            .sign{
                display: flex;
                z-index: -100;
                opacity: 0;
                position: absolute;
                margin: auto;
                // width: 105px;
                min-width: 105px;
                height: 94px;
                border-radius: 0 15px;
                justify-content: flex-start;
                align-items: center;
                font-family: $fontBig2;
                font-weight: 300;
                font-size: 18px;
                color: $white;
                line-height: 18px;
                text-transform: uppercase;
                letter-spacing: 0.8px;
                transition: opacity .2s;
                padding-left: 15px;
                padding-right: 10px;
                @include mw($sm) {
                    display: none !important;
                }
                span{
                    font-family: $fontBig;
                    font-weight: 400;
                }
                small{
                    display: block;
                    padding-top: 2px;
                    font-family: $font !important;
                    font-size: 10px;
                    line-height: 10px;
                    text-transform: uppercase;
                    font-weight: 400;
                    color: $white;
                    > span{
                        font-family: $font !important;
                        font-size: 10px;
                        line-height: 10px;
                        font-weight: 700;
                    }
                }
                &.active{
                    z-index: 2;
                    opacity: 1;
                }
                &.sign-road{
                    background-color: $white;
                    color: $notblack;
                    width: 140px;
                    height: 120px;
                    font-size: 14px;
                    line-height: 16px;
                    border-radius: 15px 0;
                    letter-spacing: 0.2px;

                }
                &.sign-samoa, 
                &.sign-seszele, 
                &.sign-malediwy, 
                &.sign-cyklady, 
                &.sign-bahamy,
                &.sign-galapagos, 
                &.sign-road{
                    @include mw($lg) {
                        display: none !important;
                    }
                }
            }
            img{
                vertical-align: top;
            }
            svg{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 1;
            }
            .desktop{
                @include mw($sm) {
                    display: none;
                }
            }
            .mobile{
                display: none;
                @include mw($sm) {
                    display: block;
                }
            }
            &.select-floor{
                svg{
                    @include mw($sm) {
                        g{
                            display: none !important;
                        }
                    }
                    g{
                        &.building{
                            &.active{
                                cursor: pointer;
                            }
                            g{
                                opacity: 0;
                                transition: opacity 0.3s ease;
                                &.active,
                                &:hover{
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__bottom{
        display: none;
        @include mw($sm) {
            display: block;
        }
        &.active{
            display: block;
        }
        .title{
            padding-top: 40px;
            padding-bottom: 20px;
            @include mw($sm) {
                text-align: center;
                font-size: 26px;
            }
        }
        .desktop{
            @include mw($sm) {
                display: none;
            }
        }
        .mobile{
            display: none;
            @include mw($sm) {
                display: block;
            }
        }
        .desc{
            padding-bottom: 40px;
            ul{
                display: flex;
                justify-content: center;
                @include mw($md) {
                    justify-content: space-between;
                    flex-wrap: wrap;
                }
                @include mw($sm) {
                    flex-direction: column;
                }
                li{ 
                    padding: 10px;
                    margin-right: 46px;
                    height: 13px;
                    color: #4a4a4a;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 14px;
                    @include mw($md) {
                        width: 50%;
                        margin-right: 0;
                    }
                    @include mw($sm) {
                        width: 100%;
                    }
                    &:before{
                        content: '';
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 7px;
                        width: 14px;
                        height: 14px;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    &.sell:before{
                        background-color: #d1d1d1;
                    }
                    &.avaliable:before{
                        background-color: #abdee2;
                    }
                    &.reserved:before{
                        background-color: #ebd570;
                    }
                    &.discount:before{
                        background-color: #d73232;
                    }
                }
            }
        }
        .choose{
            ul{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -3px;
                justify-content: center;
                li{
                    padding: 3px;
                }
            }
            label{
                display: block;
                input[type='radio']{
                    display: none;
                }
                > span{
                    display: block;
                    height: 44px;
                    width: 48px;
                    line-height: 44px;
                    color: $blue;
                    border: 1px solid $blue;
                    background-color: $white;
                    text-align: center;
                    font-family: $fontBig;
                    font-size: 20px;
                    border-radius: 10px 0;
                } 
                input[type='radio']:checked + span{
                   background-color: $blue;
                   color: $white;
                }
            }
        }
        .mobileChooseFlatLegend,
        .mobileChooseFlatLabel,
        .mobileChooseFloorLabel,
        .mobileChooseFloor{
            @include mw($sm) {
                display: none;
                &.active{
                    display: block !important;
                }
            }
        }
        .mobileChooseFloor {
            @include mw($sm) {
                > ul{
                    display: none;
                    &.active{
                        display: flex !important;
                    }
                }
            }
        }
        


        .floor{
            position: relative;
            margin: 0 auto;
            // overflow: hidden;
            .loading{
                display: none;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                background-color: $white;
                z-index: 1;
                > div{
                    font-family: $fontBig;
                    color: $navyblue;
                    font-size: 20px;
                    line-height: 20px;
                    text-align: center;
                    padding-top: 30px;
                    letter-spacing: 0.6px;
                }
                &.active{
                    display: block;
                }
            }
            .sign{
                display: flex;
                z-index: -100;
                opacity: 0;
                position: absolute;
                margin: auto;
                border-radius: 15px 0;
                align-items: center;
                padding: 0 15px;
                width: 160px;
                height: 90px;
                font-weight: 300;
                font-size: 12px;
                color: $navyblue;
                line-height: 16px;
                transition: opacity .2s;
                border: 1px solid #e0e0e0;
                background-color: $white;
                &.active{
                    z-index: 1;
                    opacity: 1;
                }
            }
            img{
                vertical-align: top;
            }
            svg{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
            img{
                vertical-align: top;
            }
            svg{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
            &.select-flat{
                svg{
                    g{
                        &.flat{
                            opacity: 0.6;
                            transition: opacity 0.3s ease;
                            &:hover:not(.sell){
                                cursor: pointer;
                                opacity: 0.9;
                            }
                        }
                    }
                }
            }
        }
    }
}