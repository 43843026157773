.sub-policy{
    padding-top: 80px;
    @include mw($sm) {
        padding-top: 40px;
    }
    .leaf{
        &--1{
            left: -236px;
            top: -350px;
        }
        &--2{
            right: -87px;
            bottom: -160px;
        }
    }
    &__content{
        .title{
            position: relative;
            text-align: center;
            color: $blue;
            font-family: $fontBig;
            font-size: 32px;
            line-height: 38px;
            span{
                color: $navyblue;
            }
            &--big{
                font-size: 44px;
                line-height: 46px;
                @include mw($sm) {
                    font-size: 40px;
                    line-height: 42px;
                }
            }
        }
        .text{
            padding-top: 20px;
            color: $notblack;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            strong{
                font-weight: 700;
            }
            a{
                text-decoration: underline;
            }
            p{
                padding: 10px 0;
            }
            ul, ol{
                margin-left: 50px;
                li{
                    padding: 0;
                }
                li:before {
                    display: none !important;
                }
            }
        }
    }   
}