.sub-video{
    padding-top: 90px;
    @include mw($sm) {
        padding-top: 40px;
    }
    .leaf{
        &--1{
            left: -238px;
            bottom: 240px;
        }
    }
    &__image{
        position: relative;
        img{
            width: 100%;
            vertical-align: top;
        }
        .play{
            position: absolute;
            bottom: 56px;
            right: 77px;
            margin: auto;
            color: $white;
            font-family: $fontBig;
            font-size: 26px;
            font-weight: 400;
            line-height: 24px;
            @include mw($sm) {
                bottom: 26px;
                right: 50px;
            }
            a{
                &:before{
                    content: '';
                    display: inline-block;
                    margin-right: 13px;
                    width: 19px;
                    height: 22px;
                    text-align: middle;
                    background-image: url(../images/play.png);
                    background-size: contain;
                    background-position: center;
                    transition: transform .3s;
                }
                &:hover:before{
                    transform: translateX(3px);
                }
            }
        }
    }
    &__video{
        cursor: pointer;
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        z-index: 999999;
        background-color: rgba(0, 0, 0, 0.6);
        &.active{
            display: block;
        }
        .content{
            padding: 40px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;   
            width: 100%;
            iframe{
                height: 90% !important;
                width: 95% !important;
                @include mw($sm) {
                    height: 95% !important;
                    width: 100% !important;
                }
            }
        }
        .close{
            position: absolute;     
            top: 0px;
            right: 0px;
            margin: auto;   
            padding: 12px;
            display: inline-block;
            vertical-align: top;
            font-family: $fontBig;
            font-size: 26px;
            text-decoration: underline; 
            line-height: 26px;
            color: #fff;
            font-weight: 400;
        }
    }
}

//slider
.sub-video {
    .container {
        // max-width: unset;
        // padding: 0;
        max-width: 1000px;

        .row {
            flex-wrap: nowrap;
        }
        .col-lg-10 {
            margin: 0;
            flex: 1;
            z-index: 1;
            max-width: unset;
        }
         .leaf--1{
             z-index: -1;
             display: none;
         }
    }
    .slick-list {
        overflow: visible;
    }
    .slick-slide {
        transition: opacity;
        transition-duration: 0.2s;
        opacity: 0.3;

        &.slick-active {
            opacity: 1;
        }
        & > div {
            margin: 0 auto;
            text-align: center;
        }
    }
    .slick-dots {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        li {
            margin: 0 0.5rem;
            position: relative;
            button {
                border: 0;
                background: transparent;
                font-size: 0;
                color: transparent;
                width: 20px;
                height: 20px;
                padding: 0;
                left: 0;
                top: 0;
                &:after {
                    content: '';
                    position: absolute;
                    background-color: transparent;
                    border: 2px solid $blue;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    top: 0%;
                    left: 0;

                }
            }
            &.slick-active {
                button {
                    &:after {
                        background-color: $blue;
                    }
                }
            }
        }
    }

    .sub-video {
        &__container {
            position: relative;
        }
    }

    

    .slick-disabled {
        display: none !important;
    }
    .slick-next, 
    .slick-prev {
        border: 0;
        background: transparent;
        position: absolute;
        top: 0;
        height: 100%;
        width: 500px;
        z-index: 10;
        text-indent: -999em;
    }
    .slick-next {
        right: -515px;
    }

    .slick-prev {
        left: -515px;
    }
    @include mw($md) {
        .slick-next, 
        .slick-prev {
            display: none !important;
        }
    }
}