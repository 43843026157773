$xxxl: 1700px;
$xxl: 1500px;
$xl: 1299px;
$lg: 1199px;
$md: 991px;
$sm: 767px;
$xs: 500px;
$xxs: 450px;

$fontBig: 'Bebas Neue', sans-serif;
$fontBig2: 'Bebas Neue 2', sans-serif;
$font: 'Open Sans', sans-serif;

$black: #000000;
$notblack: #494949;
$navyblue: #062f5f;
$white: #ffffff;
$blue: #1197cd;
$green: #42c0b6;
$greenOther: #47a2b5;
$red: #ec2324;