.home-banner{
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    max-height: 100vh;
    width: 100%;
    min-height: 642px;
    padding-top: 100px;
    @include mw($lg) {
        height: 480px;
    }
    @include mw($sm) {
        min-height: inherit;
        max-height: inherit;
        flex-direction: column;
        height: auto;
    }
    &__ytVideoContainer {
        position: relative;
        z-index: 2;
        width: 100%;
        text-align: right;
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // flex-direction: column;
        @include mw($sm) {
            order: 2;
        } 
        .g__button{
            padding-top: 15px;
        }
        > div{
            // display: flex;
            // flex-direction: column;
            width: 46vw;
            @include mw($xxxl) {
                width: 50vw;
            }
            @include mw($md) {
                width: 44vw;
            }
            @include mw($sm) {
                width: 100%;
            }
            > a,
            > iframe{
                display: block;
                width: 100%;
                > img{
                    width: 100%;
                }
            }
        }
    }
    &__ytVideo {
        aspect-ratio: 1280/720 !important;
        height: auto;
        display: inline-block;
        vertical-align: top;
        // width: 46vw;
        // @include mw($xxxl) {
        //     width: 50vw;
        // }
        // @include mw($md) {
        //     width: 44vw;
        // }
        // @include mw($sm) {
        //     width: 100%;
        // }
    }
    .g__button{
        @include mw($md) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
        > a{
            margin-left: 20px;
            @include mw($md) {
                margin-top: 10px;
                margin-left: 0;
            }
            &:first-child{
                margin-left: 0;
            }
        }
    }
    &__image{
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        margin: auto;
        z-index: 1;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow: hidden;
        display: flex;
        @include mw($sm) {
            position: relative;
            order: 2;
            height: 60vw;
            width: 100%;
            margin-top: calc(-60vw + 18px);
        }
        img{
            width: 100%;
            vertical-align: top;
        }
        &:after{
            content: '';
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }


        @include mw($md) {
            img {
                object-fit: cover;
                height: 100%;
            }
        }
    }   
    &__content{
        width: 100%;
        padding-top: 20px;
        padding-bottom: 40px;
        z-index: 2;
        @include mw($sm) {
            order: 3;
            padding-bottom: 0;
        }
    }
    &__leafs{
        position: absolute;
        left: 11%;
        top: 50%;
        transform: translateY(-50%);
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        z-index: 10 !important;
        @include mw($lg) {
            left: 20px;
        }
        @include mw($sm) {
            position: static;
            order: 1;
            transform: none;
            margin: 0;
            margin-left: 20px;
            align-self: flex-start;
            padding-bottom: 60px;
        }
        .left{
            padding-right: 10px;
        }
        .top{
            display: block;
            width: 100%;
            margin-bottom: 10px;
            margin-left: 136px;
            @include mw($sm) {
                position: absolute;
                margin: 0;
                margin-bottom: 0;
                transform: translateX(250px);
            }
        }
        .leaf{
            display: flex;
            align-items: center;
            justify-content: center;
            &--1{
                margin-top: 62px;
                border-radius: 30px 0;
                color: $white;
                background-color: $blue;
                width: 126px;
                height: 125px;
                font-family: $fontBig;
                font-size: 33px;
                font-weight: 400;
                line-height: 36px;
                text-transform: uppercase;
                letter-spacing: 0.74px;
                padding-top: 2px;
                @include mw($sm) {
                    margin-top: 50px;
                    font-size: 24px;
                    line-height: 27px;
                    width: 80px;
                    height: 80px;
                }
            }
            &--2{
                border-radius: 30px 0;
                background-color: $white;
                color: $navyblue;
                width: 232px;
                height: 122px;
                font-family: $fontBig2;
                font-size: 46px;
                font-weight: 300;
                line-height: 46px;
                padding-top: 2px;
                @include mw($sm) {
                    font-size: 32px;
                    line-height: 34px;
                    width: 150px;
                    height: 100px;
                    border: 1px solid #c8c8c8;
                }
            }
            &--3{
                margin-top: 10px;
                border-radius: 0 20px;
                background-color: $green;
                color: $white;
                width: 129px;
                height: 73px;
                font-family: $fontBig;
                font-size: 20px;
                font-weight: 400;
                line-height: 27px;
                padding-top: 2px;
                @include mw($sm) {
                    font-size: 14px;
                    line-height: 21px;
                    width: 106px;
                    height: 48px;
                }
            }
        }
    }
}