.sub-manager{
    padding-top: 80px;
    @include mw($sm) {
        padding-top: 40px;
    }
    &__content{ 
        position: relative;
        display: flex;
        flex-wrap: wrap;
        border-radius: 0 0 30px;
        border: 1px solid #e0e0e0;
        background-color: $white;
        align-items: center;
        height: 200px;
        @include mw($sm) {
            height: auto;
        }
        .left{
            flex: 0 0 auto;
            overflow: hidden;
            height: 100%;
            @include mw($sm) {
                flex: 0 0 100%;
                height: 70vw;
            }
            img{
                vertical-align: top;
                @include mw($lg) {
                    width: 200px;
                }
                @include mw($sm) {
                    width: 100%;
                }
            }
        }
        .right{
            flex: 1 1 auto;
            padding-left: 84px;
            @include mw($lg) {
                padding-left: 50px;
            }
            @include mw($sm) {
                flex: 0 0 100%;
                padding: 20px;
            }
            .title{
                padding-top: 26px;
                font-family: $fontBig;
                color: $blue;
                font-size: 26px;
                line-height: 26px;
                @include mw($sm) {
                    padding-top: 0;
                }
            }
            .text{
                padding-top: 0;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: $navyblue;
            }
            .img {
                margin-top: 14px;
                max-width: 250px;
            }
        }
        .pin{
            position: absolute;
            top: -32px;
            right: 36px;
            margin: auto;
            width: 97px;
            height: 97px;
            border: 3px solid $blue;
            background-color: $white;
            color: $blue;
            font-family: $fontBig;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-top: 5px;
            @include mw($sm) {
                right: 20px;
            }
            p {
                line-height: 16px;
            }
        }
    }
    &__button{
        padding-top: 40px;
    }
}

.sub-manager__content {
    border-radius: 0;
    .left img {
        max-width: 206px;
        @include mw($md) {
            max-width: 100%;
        }
    }
    & + .sub-credit {
        padding-top: 0;
        padding: 45px;
        border: 1px solid #e0e0e0;
        border-radius: 0 0 30px;
        border-top: 0;
        @include mw($md) {
            padding: 20px;
        }

        .item {
            border: 0;
            margin-top: 0;

            .text {
                color: #434343;
                font-family: Bebas Neue,sans-serif;
                font-size: 22px;
                line-height: 30px;
                color: #5e5e5e;
            }

            .item__left {
                width: 80px;
                p {
                    font-size: 40px;
                }
            }

            .item__right {
                padding-top: 0;
                padding-bottom: 0;
                padding-right: 0;
            }

            & + .item {
                margin-top: 35px;
                @include mw($md) {
                    margin-top: 20px;
                }
            }
        }
    }
}

.sub-credit .item--blue .item__right .text span {
    color: #0075bc;
}

.row.person:not(:last-of-type) {
    margin-bottom: 45px;
}