@mixin mw($width){
    @media (max-width: $width) {
        @content;
    }
}
@mixin margin-top($top){
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: $top;
}

@mixin icon_area(
    $social-icon-size: 20px,
    $social-icon-gutter-x: 0,
    $social-icon-gutter-y: 0,
    $with-container: false
) {
    @if($with-container) {
        width: calc(#{$social-icon-size} + 2 * #{$social-icon-gutter-x});
        height: calc(#{$social-icon-size} + 2 * #{$social-icon-gutter-y});
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        & > i ,
        & > a {
            background-size: contain;
            background-repeat: no-repeat;
            width: $social-icon-size;
            height: $social-icon-size;
            color: transparent;
        }
    } @else {
        display: block;
        margin: $social-icon-gutter-y $social-icon-gutter-x;
        background-size: contain;
        background-repeat: no-repeat;
        width: $social-icon-size;
        height: $social-icon-size;
        color: transparent;
    }

}
@mixin icon(
    $icon-name: ''
) {
    background-image: url(#{$icon-name});
}

$phone-small:    400px;
$phone:          450px;
$smartphone:     767px;
$tablet:         1024px;
$smaller-screen: 1180px;
$small-screen:   1280px;
$medium-screen:  1366px;
$large-screen: 1600px;
$resize-height:  660px;

@mixin phone($set: 'min') {
  @if $set == min { @media screen and (min-width: $phone +1px) { @content; } }
  @else if $set == max { @media screen and (max-width: $phone ) { @content; } }
}
@mixin smartphone($set: 'min') {
  @if $set == min { @media screen and (min-width: $smartphone +1px) { @content; } }
  @else if $set == max { @media screen and (max-width: $smartphone ) { @content; } }
}
@mixin tablet($set: 'min') {
  @if $set == min { @media screen and (min-width: $tablet +1px) { @content; } }
  @else if $set == max { @media screen and (max-width: $tablet ) { @content; } }
  
}
@mixin smaller_screen($set: 'min') {
  @if $set == min { @media screen and (min-width: $smaller-screen +1px) { @content; } }
  @else if $set == max { @media screen and (max-width: $smaller-screen) { @content; } }
}
@mixin small_screen($set: 'min') {
  @if $set == min { @media screen and (min-width: $small-screen +1px) { @content; } }
  @else if $set == max { @media screen and (max-width: $small-screen) { @content; } }
}
@mixin medium_screen($set: 'min') {
  @if $set == min { @media screen and (min-width: $medium-screen +1px) { @content; } }
  @else if $set == max { @media screen and (max-width: $medium-screen ) { @content; } }
}

@mixin large_screen($set: 'min') {
  @if $set == min { @media screen and (min-width: $large-screen +1px) { @content; } }
  @else if $set == max { @media screen and (max-width: $large-screen) { @content; } }
}

@mixin media($breakpoint: 'smaller_screen', $set: 'min') {

  @if       $breakpoint == phone            {  @include phone($set)           { @content; } }
  @else if  $breakpoint == smartphone       {  @include smartphone($set)      { @content; } }
  @else if  $breakpoint == tablet           {  @include tablet($set)          { @content; } }
  @else if  $breakpoint == smaller_screen   {  @include smaller_screen($set)  { @content; } }
  @else if  $breakpoint == small_screen     {  @include small_screen($set)    { @content; } }
  @else if  $breakpoint == medium_screen    {  @include medium_screen($set)   { @content; } }
  @else if  $breakpoint == large_screen     {  @include large_screen($set)    { @content; } }
}
