.home-image{
    // height: 700px;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: cover;
    position: relative;
    z-index: 3;
    height: 36vw;
    max-height: 700px;
    @include mw($lg) {
        // height: 480px;
        height: 50vw;
    }
    // @include mw($sm) {
    //     height: 340px;
    // }
}