.svgIcons {
    .icons{
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > div{
            text-align: center;
            @include mw($lg) {
                max-width: 33.33%;
                flex: 0 0 33.33%;
            }
            @include mw($sm) {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
        &__content{
            display: inline-block;
            vertical-align: top;
            padding: 20px 0;
            margin: 20px 0;
            text-align: center;
            padding-top: 15px;
            width: 166px;
            height: 169px;
            border: 1px solid #c8c8c8;
            background-color: $white;
            border-radius: 42px 0;
            .image{
                display: flex;
                justify-content: center;
                align-items: flex-end;
                height: 80px;
                .image--move{
                    margin-left: 10px;
                }
                img{
                    vertical-align: top;
                }
            }
            h1,h2,h3,h4,h5,h6,p{
                padding-top: 14px;
                display: block;
                color: #284b75;
                font-family: $fontBig;
                font-size: 18px;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }
}
.sub-icons{
    padding-top: 80px;
    padding-bottom: 40px;
    @include mw($sm) {
        padding-bottom: 0;
        padding-top: 40px;
    }
    .sub-flat__iconSlider {
        padding-left: 0;
        padding-right: 0;
    }
    &__content{
        .title{
            color: $blue;
            font-family: $fontBig; 
            font-size: 40px;
            font-weight: 400;
            line-height: 42px;
            text-transform: uppercase;
            text-align: center;
            span{
                color: $navyblue;
            }
        }

    }
}