.footer{
    padding-top: 40px;
    padding-bottom: 50px;
    &--height{
        display: flex;
        align-items: center;
        height: 75px;
        @include mw($sm) {
            height: auto;
            padding: 10px 0;
        }
    }
    &--border{
        border-top: 1px solid #ededed;
        background-color: $white;
        @include mw($sm) {
            padding: 20px 0;
        }
    }
    &__text{
        color: #2c2745;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        span{
            text-transform: uppercase;
        }
        a{
            color: #2c2745;
            font-size: 12px;
            font-weight: 700;
            line-height: 22px;
            text-transform: uppercase;
            &:hover{
                text-decoration: underline;
            }
        }
        img{
            vertical-align: top;
        }
    }
    &__image{
        img{
            vertical-align: top;
        }
        > div,
        > a{
            margin-right: 15px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    &__social{
        a{
            display: inline-block;
            vertical-align: top;
            width: 24px;
            height: 24px;
            background-image: url(../images/facebook.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: opacity .2s;
            &:hover{
                opacity: 0.9;
            }
        }
    }
    &__contact{
        display: flex;
        flex-wrap: wrap;
        @include mw($sm) {
            margin: 0 -20px;
        }
        > a{
            // flex: 0 0 50%;
            // max-width: 50%;
            // flex: 0 0 33.33%;
            // max-width: 33.33%;
            flex: 1;
            // @include mw($sm) {
            //     flex: 0 0 100%;
            //     max-width: 100%;
            // }
            height: 44px;
            font-family: $fontBig;
            font-size: 20px;
            font-weight: 400;
            line-height: 44px;
            letter-spacing: 0.5px;
            text-align: center;
            transition: background-color .3s, color .3s;
            @include mw($sm) {
                font-size: 16px;
            }
            @include mw($xs) {
                font-size: 14px;
            }
            span{
                position: relative;
                display: inline-block;
                vertical-align: middle;
                &:before,
                &:after{
                    content: '';
                    position: absolute;
                    display: block;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    transition: opacity .3s;
                }
            } 
            &.mail{
                background-color: $blue;
                color: $white;

                span{
                    position: relative;
                    width: 19px;
                    height: 19px;
                    margin-right: 15px;
                    @include mw($sm) {
                        margin-right: 10px;
                        width: 15px;
                    }
                    &:before{
                        background-image: url(../images/mailcolor.png);
                        opacity: 0;
                    }
                    &:after{
                        background-image: url(../images/mailwhite.png);
                        opacity: 1;
                    }
                }
                &:hover{
                    background-color: #ededed;
                    color: $navyblue;
      
                    span{
                        &:before{
                            opacity: 1;
                        }
                        &:after{
                            opacity: 0;
                        }
                    }
                }
            }
            &.phone{
                background-color: #ededed;
                color: $navyblue;
                max-width: 22%;
                min-width: 175px;
                @include mw($sm) {
                    max-width: unset;
                    min-width: unset;
                    &:nth-child(4){
                        display: none;
                    }
                }
                span{
                    margin-right: 15px;
                    position: relative;
                    width: 24px;
                    height: 24px;
                    @include mw($sm) {
                        margin-right: 10px;
                        width:16px;
                    }
                    &:before{
                        background-image: url(../images/phonecolor.png);
                        opacity: 1;
                    }
                    &:after{
                        background-image: url(../images/phonewhite.png);
                        opacity: 0;
                    }
                }
                &:hover{
                    color: $white;
                    background-color: $blue;
                    span{
                        &:before{
                            opacity: 0;
                        }
                        &:after{
                            opacity: 1;
                        }
                    }
                }
            }
            &.url{
                // background-color: #ededed;
                // color: $navyblue;
                background-color: $blue;
                color: $white;
                @include mw($xs) {

                    max-width: 94px;

                }
                &:hover{
                    background-color: #ededed;
                    color: $navyblue;
                }
            }
        }
    }
}
.fixed-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 5;
}