$menuHeight: 126px;
$menuMobileHeight: 70px;

.header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: transparent;
    z-index: 10;
    // border-bottom: 1px solid transparent;
    // transition: background-color 400ms linear;
    // background: white;
    &__space{
        height: $menuHeight;
        @include mw($lg) {
            height: 90px;
        }
        @include mw($sm) {
            height: $menuMobileHeight;
        }
    }
    .logo{
        position: relative;
        img{
            vertical-align: top;
            width: 117px;
            transition: width .4s;
            @include mw($lg) {
                width: 90px;
            }
            @include mw($sm) {
                width: 70px;
            }
        }
        .white{
            position: absolute;
            left: 0;
            top: 0;
            margin: auto;
        }
        .color{
            opacity: 0;
        }
    }
    .nagroda{
        position: absolute;
        bottom: -20px;
        left: 20px;
        z-index: 2;
        margin: auto;
        transform: translateY(100%);
        background-color: #fff;
        @include mw($lg) {
            left: auto;
            right: 0;
            transform: translateX(100%);
            top: 0;
            bottom: auto;
            border-radius: 0 15px !important;
            padding: 5px 10px !important;
        }
        img{
            transition: all .4s;
            @include mw($lg) {
                width: 32px !important;
            }
        }
        
    }
    .banner{
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        transform: translateY(100%);
        @include mw($sm) {
            left: auto;
            width: 50%;
        }
        img{
            vertical-align: top;
            width: 50%;
            @include mw($sm) {
                width: 100%;
            }
        }
    }
    .menu{
        position: relative;
        height: $menuHeight;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: height .4s;
        @include mw($lg) {
            height: 90px;
        }
        @include mw($sm) {
            height: $menuMobileHeight;
        }
        .hamburger-button{
            display: none;
            @include mw($lg) {
                display: block;
            }
            .hamburger{
                vertical-align: top;
                padding: 5px;
            }
        }
        .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
            background-color: $white;
        }
        &__nav{
            @include mw($lg) {
                display: none;
            }
            > ul{
                display: flex;
                > li{
                    color: $white;
                    font-family: $fontBig;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 32px;
                    margin-right: 40px;
                    &:last-child{
                        margin-right: 0;
                    }
                    > a.selected{
                        &:after{
                            height: 2px;
                        }
                    }
                    a{
                        position: relative;
                        transition: color .3s;
                        &:after{
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                            height: 0;
                            background-color: $blue;
                            transition: height .2s;
                        }
                        &:hover{
                            color: $blue;
                        }
                    }
                    &.menu-item-has-children{
                        position: relative;
                        > a,
                        > span{
                            display: inline-block;
                            vertical-align: top;
                            z-index: 1;
                            cursor: pointer;
                        }
                        > ul{
                            display: none;
                            position: absolute;
                            z-index: 3;
                            bottom: 0;
                            left: 0;
                            transform: translateY(100%);
                            background-color: $white;
                            border: 1px solid #e1e1e1;
                            width: 200px;
                            padding: 10px 20px;
                            box-shadow: 4px 4px 2px rgba(0,0,0,0.06);
                            li{
                                padding: 8px 0;
                                font-size: 18px;
                                font-family: $fontBig;
                                line-height: 20px;
                                text-transform: uppercase;
                            }
                        }
                        &:hover{
                            > ul{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        &__mobile{
            display: none;
            position: absolute;
            top: $menuHeight;
            left: 0;
            right: 0;
            margin: auto;
            background-color: $white;
            transition: transform .5s, background-color .6s;
            transform: translateX(-100%);
            z-index: 10;
            padding: 10px 0;
            height: calc(100vh - #{$menuHeight});
            overflow-y: scroll;
            vertical-align: top;
            @include mw($lg) {
                top: 90px;
                height: calc(100vh - 90px);
            }
            @include mw($sm) {
                top: $menuMobileHeight;
                height: calc(100vh - #{$menuMobileHeight});
            }
            > div{
                padding-top: 30px;
                padding-bottom: 30px;
            }
            @include mw($lg) {
                display: inline-block;
            }
            &.active{
                transform: translateX(0%);
            }
            .nav{
                li{
                    &.active{
                        > a{
                            color: $blue;
                        }
                    }
                }
                ul{
                    li{
                        display: block;
                        padding: 10px 0;
                        font-size: 22px;
                        font-weight: 500;
                        line-height: 24px;
                        .sub-menu{
                            display: none;
                            padding-top: 10px;
                            padding-left: 15px;
                            li{
                                font-size: 20px;
                            }
                            &--stages{
                                flex-wrap: wrap;
                                padding-left: 0;
                                padding-top: 20px;
                                max-width: 450px;
                                li{ 
                                    padding: 0 10px;
                                    max-width: 50%;
                                    flex: 0 0 50%;
                                    font-size: 20px;
                                    font-family: $fontBig;
                                    font-weight: 500;
                                    line-height: 28px;
                                    padding-bottom: 10px;
                                    img{
                                        width: 100%;
                                        vertical-align: top;
                                        border-radius: 15px 0;
                                    }
                                    &.blank{
                                        color: #c4c4c4 !important;
                                    }
                                }
                            }
                        }
                        &.menu-item-has-children{
                            > span span,
                            > a span{
                                display: inline-block;
                                vertical-align: top;
                                background-image: url(../images/marrow.png);
                                background-position: center;
                                background-repeat: no-repeat;
                                width: 24px;
                                height: 24px;
                                margin-left: 12px;
                                z-index: 1;
                                cursor: pointer;
                            }
                        }
                        &.active-open{
                            > span span,
                            > a span{
                                background-image: url(../images/muparrow.png);
                            }
                            .sub-menu{
                                display: block;
                                &--stages{
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.other-site{
        .logo {
            .white{
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                margin: auto;
            }
            .color{
                opacity: 1;
                position: static;
            }
        }
        .menu__nav>ul>li{
            color: $navyblue;
        }
        .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
            background-color: $navyblue;
        }
    }
    @include mw($sm) {
        .logo {
            .white{
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                margin: auto;
            }
            .color{
                opacity: 1;
                position: static;
            }
        }
        .menu__nav>ul>li{
            color: $navyblue;
        }
        .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
            background-color: $navyblue !important;
        }
    }
    &.activemenu{
        z-index: 12;
        @include mw($lg) {
            background-color: $white;
            // border-color: #ededed;
            .logo {
                .white{
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin: auto;
                }
                .color{
                    opacity: 1;
                    position: static;
                }
            }
            .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
                background-color: $navyblue;
            }
        }
    }
    &.active-stages{
        background-color: $white !important;
        transition: none;
        // border-color: #ededed !important;
        z-index: 12;
        .logo {
            .white{
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                margin: auto;
            }
            .color{
                opacity: 1;
                position: static;
            }
        }
        .menu__nav>ul>li{
            color: $navyblue;
        }
    }
    &.active{
        background-color: $white;
        // border-color: #ededed;
        z-index: 12;
        .logo {
            .white{
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                margin: auto;
            }
            .color{
                opacity: 1;
                position: static;
            }
            img{
                width: 90px;
                @include mw($sm) {
                    width: 70px;
                }
            }
        }
        .nagroda{
            img{
                width: 50px;
            }
        }
        .menu{
            height: 90px;
            @include mw($sm) {
                height: 70px;
            }
        }
        .menu__nav>ul>li{
            color: $navyblue;
        }
        .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
            background-color: $navyblue;
        }
    }
    &__stages{
        display: none;
        padding-top: 65px;
        padding-bottom: 55px;
        position: absolute;
        z-index: 12;
        background: white;
        left: 0;
        width: 100%;
        &.active{
            // display: block;
        }
        @include mw($lg) {
            display: none !important;
        }
        .item{
            &__image{
                border-radius: 15px 0;
                overflow: hidden;
                img{
                    width: 100%;
                    vertical-align: top;
                    transition: transform .3s;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
                
            }
            &__title{
                padding-top: 10px;
                color: $blue;
                font-family: $fontBig;
                font-size: 24px;
                line-height: 38px;
                a{
                    transition: color .3s;
                    &:hover{
                        color: $navyblue;
                    }
                }
            }
            &.blank{
                .item__image img{
                    transform: scale(1);
                }
                .item__title{
                    color: #c4c4c4 !important;
                }
            }
        }
        &--page{
            display: block !important;
            position: static !important;
            background: transparent;
            padding-bottom: 0;
            padding-top: 90px;
            @include mw($sm) {
                padding-top: 50px;
            }
            .title{
                text-align: center;
            }
            .col-sm-2{
                @include mw($md) {
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                }
                @include mw($xs) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }
        }
    }
}
.overlay{
    cursor: pointer;
    position: fixed;
    display: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba($white, 0);
    transition: transform .4s, background-color .6s;
    transform: translateX(-100%);
    z-index: 8;
    @include mw($lg) {
        display: block;
    }
    &.active{
        transform: translateX(0%);
        background-color: rgba($white, 0.90);
    }
}

// .p202 .header {
//     background: transparent;
//     &.active {
//         background-color: #fff;
//         border-color: #ededed;
//     }
// }