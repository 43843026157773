.sub-credit{
    padding-top: 45px;
    @include mw($sm) {
        padding-top: 40px;
    }
    .leaf{
        &--1{
            top: 95px;
            left: 375px;
        }
    }
    .ekspert{
        padding-top: 50px;
        text-align: center;
        @include mw($sm) {
            padding-top: 40px;
        }
    }
    .button{
        padding-top: 50px;
        text-align: center;
        @include mw($sm) {
            padding-top: 40px;
        }
    }
    .item{
        display: flex;
        border-radius: 0 30px;
        border: 1px solid #e0e0e0;
        background-color: #ffffff;
        @include mw($sm) {
            flex-wrap: wrap;
        }
        &__left{
            flex: 0 0 auto;
            width: 166px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 28px;
            background-color: $blue;
            @include mw($sm) {
                flex: 0 0 100%;
                padding: 15px 0;
            }
            p{
                margin-top: 4px;
                color: $white;
                font-family: $fontBig;
                font-size: 40px;
                line-height: 40px;
            }
            img{
                max-width: 77px;
                vertical-align: top;
            }
        }
        &__right{
            flex: 1 1 auto;
            padding-left: 40px;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-right: 60px;
            @include mw($sm) {
                flex: 0 0 100%;
                padding: 20px !important;
            }
            .text{
                color: #494949;
                @include mw($sm) {
                    br{
                        display: none;
                    }
                }
            }
        }
        &--green{
            margin-top: 50px;
            @include mw($sm) {
                margin-top: 40px;
            }
            .item__left{
                background-color: #78be20;
            }
            .item__right{
                padding-top: 29px;
                padding-bottom: 25px;
                .text{
                    color: #434343;
                    font-family: $fontBig;
                    font-size: 22px;
                    line-height: 30px;
                    color: #5e5e5e;

                    span{
                        color: #78be20;
                    }
                }
            }
        }
    }
}