.sub-stages{
    padding-top: 120px;
    @include mw($lg) {
        padding-top: 100px;
    }
    &__image{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    &__sign{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 22px;
        margin: auto;
        // @include mw($lg) {
        //     bottom: 5px
        // }
        // @include mw($sm) {
            position: static;
            padding-top: 5px;
        // }
        .content{
            color: $white;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: #000;
            @include mw($sm) {
                // color: #000;
                font-size: 10px;
                line-height: 10px;
                br{
                    display: none;
                }
            }
        }
    }
    &--page{
        @include mw($sm) {
            padding-top: 40px;
        }
    }
}