.sub-info{
    padding-top: 100px;
    @include mw($sm) {
        padding-top: 40px;
    }
    &--top{
        padding-top: 33px;
    }
    // &--notitle{
    //     @include mw($sm) {
    //         padding-top: 0px;
    //     }
    // }
    &--toploc{
        padding-top: 50px;
        @include mw($sm) {
            padding-top: 40px;
        }
    }
    &--centertitle{
        padding-top: 50px;
        @include mw($sm) {
            padding-top: 0px;
        }
    }
    .mobile-directions{
        display: none;
        @media (max-width: 539px){
            display: block;
        }
    }
    .leaf{
        &--1{
            left: -236px;
            bottom: -103px;
        }
        &--2{
            bottom: 157px;
            left: 62px;
        }
        &--3{
            left: -418px;
            bottom: -83px;
        }
        &--4{
            left: -236px;
            top: -350px;
        }
        &--5{
            left: -418px;
            bottom: -60px;
        }
    }
    &__content{
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &--border{
            padding: 20px;
            border-radius: 15px 0;
            border: 1px solid #e0e0e0;
        }
        &--3d{
            @include mw($sm) {
                .title{
                    text-align: center;
                }
                .g__button{
                    padding-top: 20px;
                    text-align: center;
                }
            }
        }
        .title{
            color: $blue;
            font-family: $fontBig;
            font-size: 32px;
            line-height: 38px;
            display: block;
            max-width: 880px;
            margin: 0 auto;
            text-align: center;
            &--big{
                font-size: 44px;
                line-height: 46px;
                @include mw($sm) {
                    font-size: 40px;
                    line-height: 42px;
                }
            }
            &--center{
                text-align: center;
                @include mw($sm) {
                    text-align: left;
                }
            }
            @include mw($sm) {
                font-size: 40px;
                line-height: 42px;
                br{
                    display: none;
                }
            }
            span{
                color: $navyblue;
            }
        }
        .text{
            padding-top: 16px;
            &--color{
                color: $navyblue;
            }
            &--lineheight{
                line-height: 26px;
            }
            &--bigli{
                padding-top: 0;
                ul{
                    margin-left: 30px;
                    li{
                        padding: 8px 0;
                        &:before{
                            background-image: url(../images/ok.png);
                            width: 30px;
                            margin-left: -30px;
                        }
                    } 
                }
            }
            &--contact{
                padding-top: 68px;
                font-size: 16px;
                line-height: 18px;
                @include mw($lg) {
                    padding-top: 16px;
                }
                p{
                    padding: 5px 0;
                }
                strong{
                   font-size: 17px;
                   margin-bottom: 5px;
                   text-transform: uppercase;
                   display: block;
                }
                small{
                    display: block;
                }
            }
            @include mw($lg) {
                br{
                    display: none;
                }
            }
        }
        .link{
            padding-top: 25px;
            &--center{
                text-align: center;
                @include mw($sm) {
                    text-align: left;
                }
            }
        }
    }
    &__image{
        position: relative;
        @include mw($sm) {
            padding-top: 20px;
        }
        &--mapborder{
            img{
                border: 1px solid #e6e6e6;
                box-sizing: content-box;
            }
        }
        &--left{
            img{
                border-radius: 0 30px;
            }
        }
        &--right{
            img{
                border-radius: 30px 0;
            }
        }
        &--city{
            margin-bottom: -40px;
            display: flex;
            justify-content: center;
            img{
                width: auto !important;
                max-width: inherit;
                @include mw($lg) {
                    max-width: 100%;
                    width: 100% !important;
                }
            }
        }

        img{
            width: 100%;
            vertical-align: top;
            height: auto;
        }
    }
    &--download{
        .custom-row{
            margin-right: -5px;
            margin-left: -5px;
        }
        .custom-col{
            padding-right: 5px;
            padding-left: 5px;
        }
        .sub-info__image{
            img{
                height: 400px;
                object-fit: cover;
                border-radius: 0;
                @include mw($lg) {
                    height: 300px;
                }
                @include mw($sm) {
                    height: auto;
                }
            }
            &--left{
                img{
                    border-bottom-left-radius: 30px;
                }
            }
            &--right{
                img{
                    border-top-left-radius: 30px;
                }
            } 
        }
    }
    &--eq{
        .sub-info__content {
            .title{
                font-size: 32px !important;
                line-height: 38px !important;
            }
            .text{
                line-height: 22px !important;
            }
        }




    }
}

.sub-info .map {
    @include mw($md) {
        padding: 0;
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 30px;
            left: 0; right: 0;
            width: 100%; height: 20px;
            margin: auto;
            background: url('/assets/images/scroll-touch.svg') no-repeat center;
            pointer-events: none;
        }
        .sub-info__image {
            width: 100vw;
            overflow: hidden;
            overflow-x: scroll;
            position: relative;
            img {
                min-width: 1090px;
            }
        }
    }
}