.sub-dev{
    padding-top: 20px;
    .leaf{
        &--1{
            left: -236px;
            top: -350px;
        }
        &--2{
            right: -87px;
            top: 28px;
        }
    }
    &__content{
        .title{
            position: relative;
            text-align: center;
            color: $blue;
            font-family: $fontBig;
            font-size: 32px;
            line-height: 38px;
            span{
                color: $navyblue;
            }
            &--big{
                font-size: 44px;
                line-height: 46px;
                @include mw($sm) {
                    font-size: 40px;
                    line-height: 42px;
                }
            }
        }
        .text{
            padding-top: 20px;
            color: $notblack;
            font-size: 14px;
            font-weight: 400;
            line-height: 26px;
            strong{
                font-weight: 700;
            }
        }
        .link{
            padding-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            > div{
                margin: 10px 14px;
            }
        }
        .image{
            margin: 25px 0;
            @include mw($lg) {
                margin: 20px 0;
            }
            @include mw($sm) {
                margin: 10px 0;
            }
            img{
                vertical-align: top;
                border-radius: 0 30px;
                width: 100%;
            }
        }
        .logo{
            padding-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            > a, > img{
                margin: 20px 30px;
            }
            img{
                vertical-align: top;
            }
        }
        .voucher{
            position: relative;
            padding-top: 30px;
            display: flex;
            justify-content: center;
            img{
                vertical-align: top;
                height: auto;
            }
        }
    }   
}