html, *{
    font-family: $font;
    font-weight: 400;
    color: $notblack;
    line-height: 22px;
    font-size: 14px;
}
body{
    background-color: #fff !important;
}
#adminBar{
    z-index: 100 !important;
}
// .gallery-js .open-video-js:after{
//     background-image: url(/assets/images/playtext.png) !important;
// }
.noactive{
    cursor: default !important;
}
.goto .g__info.sell{
    a, span{
        &:hover{
            background-color: $white;
            color: $black;
        }
    }
}
.g{
    &--cr{
        margin-left: -30px;
        margin-right: -30px;
        @include mw($lg) {
            margin-left: -20px;
            margin-right: -20px;
        }
    }
    &--cc{
        padding-left: 30px;
        padding-right: 30px;
        @include mw($lg) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    &--center{
        text-align: center !important;
    }
    &__nagroda{
        border: 1px solid #c8c8c8;
        border-radius: 0 30px;
        padding: 10px 20px;
        display: inline-block;
        vertical-align: top;
        .addbg{
            background-color: $white;
        }
        img{
            vertical-align: top;
            width: 72px;
            @include mw($sm) {
                width: 50px;
            }
        }
    }
    &__title{
        padding-bottom: 40px;
        color: $blue;
        font-family: $fontBig;
        font-size: 30px;
        line-height: 32px;
        text-align: center;
        @include mw($sm) {
            text-align: left;
        }
        &.separated{
            padding-top: 40px;
            padding-bottom: 0;
            font-size: 40px;
            line-height: 40px;
            text-align: center;
            @include mw($sm) {
                font-size: 30px;
                line-height: 32px;
            }
        }
        &.big{
            font-size: 38px;
            line-height: 38px;
            @include mw($sm) {
                font-size: 30px;
                line-height: 32px;
            }
        }
        span{
            color: $navyblue;
        }
        .menu{
            padding-top: 6px;
            ul{
                display: flex;
                justify-content: center;
                li{
                    flex: 0 0 auto;
                    text-align: center;
                    position: relative;
                    font-family: $fontBig;
                    color: $navyblue;
                    font-size: 16px;
                    line-height: 16px;
                    letter-spacing: 0.8px;
                    cursor: pointer;
                    margin: 0 7px;
                    transition: color .3s;
                    &:after{
                        content: '';
                        position: absolute;
                        margin: auto;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 1px;
                        width: 100%;
                        background-color: transparent;
                        transition: background-color .3s;
                    }
                    &:hover{
                        color: $blue;
                    }
                    &.active{
                        color: $blue;
                        &:after{
                            background-color: $blue;
                        }
                    }
                }
            }
        }
    }
    &__breadcrumb{
        margin-top: -26px;
        position: relative;
        z-index: 11;
        @include mw($lg) {
            margin-top: 25px;
        }
        ol{
            list-style: none;
            display: flex;
            li{
                color: #a8a8a8;
                font-family: $fontBig;
                font-size: 13px;
                line-height: 15px;
                &:after{
                    content: '/';
                    display: inline-block;
                    margin: 0 5px;
                }
                a:hover{
                    color: $navyblue;
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
    &__leaf{
        position: absolute;
        z-index: -1;
        width: 707px;
        height: 711px;
        border: 1px solid #e0e0e0;
        background-color: $white;
        border-radius: 192px 0;
        &--big{
            border-radius: 240px 0;
            width: 848px;
            height: 847px;
        }
        @include mw($sm) {
            display: none;
        }
    }
    &__icons-box{
        margin: -18px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        > div{
            height: 122px;
            width: 122px;
            margin: 18px;
            border: solid 1px #c8c8c8;
            border-top-left-radius: 24px;
            border-bottom-right-radius: 24px;
            padding-top: 8px;
            text-align: center;
            img{
                vertical-align: top;
                width: 60px;
            }
            p{
                margin-top: 4px;
                text-align: center;
                font-family: $fontBig;
                font-size: 13px;
                line-height: 16px;
                color: #284b75;
            }
        }
    }
    &__info{
        position: relative;
        a, span{
            display: inline-block;
            vertical-align: top;
            width: 129px;
            height: 22px;
            border-radius: 5px 0;
            font-family: $fontBig;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 1.4px;
            text-align: center;
            max-width: 100%;
            transition: color .3s, background-color .3s;
            overflow: hidden;
            &.big{
                height: 28px;
                line-height: 28px;
            }
        }
        &.reserved{
            a, span{
                background-color: #ebd570;
                border: 1px solid #ebd570;
                color: $black;
            }
        }
        .gotowe{
            background-color: #062f5f;
            border: 1px solid #062f5f;
            color: $white;
            &--mobile{
                height: auto;
                width: 44px !important;
                line-height: 14px;
                padding-top: 1px;
            }
        }
        &.ask{
            a, span{
                // background-color: #a8a8a8;
                // border: 1px solid #a8a8a8;
                background-color: $green;
                border: 1px solid $green;
                color: $white;
                &:hover{
                    background-color: $white;
                    color: $black;
                }
            }
        }
        .rataod{
            margin-top: 5px;
            border-color: $blue !important;
            color: $navyblue !important;
            background-color: $white !important; 
            &.nomargin{
                margin-top: 0;
            }
            b{
                color: $blue !important;
                font-weight: 400;
                transition: color .3s;
            }
            &:hover{
                background-color: $blue !important;
                color: $white !important;
                b{
                    color: $white !important;
                }
            }
        }
        &.sell{
            a, span{
                background-color: #424242;
                border: 1px solid #424242;
                color: $white;
            }
        }
        &.blue{
            a, span{
                background-color:  $blue;
                border: 1px solid  $blue;
                color: $white;
            }
        }
        &.discount{
            a, span{
                background-color: #d73232;
                border: 1px solid #d73232;
                color: $white;
            }
        }
        &.important{
            a, span{
                background-color: #d73232;
                border: 1px solid #d73232;
                color: $white;
            }
        }
        &.small{
            span{
                border-radius: 5px 0;
                font-family: $fontBig;
                font-size: 11px;
                line-height: 28px;
                letter-spacing: 0px;
                padding: 0 5px;
                width: 140px;
                height: 28px;
            }
        }
        &.full{
            span{
                width: 100%;
            }
        }
        &.big{
            span{
                border-radius: 10px 0;
                font-family: $fontBig;
                font-size: 20px;
                line-height: 46px;
                letter-spacing: 0px;
                padding: 0 10px;
                width: 100%;
                height: 46px;
                @include mw($sm) {
                    font-size: 14px;
                    padding: 0;
                }
            }
        }
        &.square{
            span{
                width: 200px;
                border-radius: 0 !important;
            }
        }
    }
    &__rata{
        position: absolute;
        z-index: 3;
        top: -4px;
        left: 50%;
        transform: translateX(-50%) translateY(-100%);
        display: none;
        width: 230px;
        background-color: $white;
        border: 1px solid #dedede;
        padding: 20px;
        padding-top: 25px;
        border-radius: 20px 0;
        text-align: left;
        cursor: default;
        box-shadow: 4px 4px 8px 1px rgba(0,0,0,.2);
        &--mieszkanie{
            @include mw($sm) {
                left: 25%;
            }
        }
        &.show{
            display: block;
        }
        .close{
            position: absolute;
            cursor: pointer;
            top: 10px;
            right: 10px;
            display: block;
            width: 12px;
            height: 12px;
            background-image: url(../images/close.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        ul, ol{
            margin-left: 14px;
            li{
                padding: 2px 0;
                font-size: 13px;
                line-height: 15px;
            }
            li:before {
                content: '.';
                display: inline-block;
                vertical-align: top;
                background-image: url(../images/ligreen.png);
                background-position: center left;
                background-repeat: no-repeat;
                line-height: inherit;
                width: 14px;
                margin-left: -14px;
                font-size: 0;
            }
        }
        a{
            margin-top: 10px;
            background-color: $green !important;
            border: 1px solid $green !important;
            color: $white !important;
            &:hover{
                background-color: $white !important;
                color: $black !important;
            }
        }
    }
    &__button{
        > button,
        > a{
            display: inline-block;
            vertical-align: top;
            padding: 0;
            text-transform: uppercase;
            color: $white;
            background-color: $blue;
            font-family: $fontBig;
            font-size: 14px;
            letter-spacing: 2.8px;
            font-weight: 400;
            min-width: 167px;
            height: 28px;
            line-height: 28px;
            border-radius: 5px 0;
            border: 1px solid $blue;
            text-align: center;
            transition: background-color .3s, color .3s;
            padding: 0 50px;
            max-width: 100%;
            @include mw($sm) {
                padding: 0 20px;
            }
            &:hover{
                background-color: $white;
                color: $blue;
            }
            &.padding{
                padding: 0 20px;
            }
            &.font{
                font-family: $fontBig2;
                font-weight: 300;
            }
            &.height{
                line-height: 36px;
                height: 36px;
                border-radius: 6px 0;
            }
            &.small{
                width: 128px;
                min-width: 128px;
                padding: 0;
            }
            &.medium{
                height: 36px;
                line-height: 36px;
                font-size: 18px;
                letter-spacing: 0.45px;
                width: 244px;
            }
            &.green{
                background-color: $green;
                border: 1px solid $green;
                &:hover{
                    background-color: $white;
                    color: $green;
                }
            }
            &.red{
                background-color: $red;
                border: 1px solid $red;
                &:hover{
                    background-color: $white;
                    color: $red;
                }
            }
            &.big{
                font-size: 20px;
                letter-spacing: 2px;
                height: 45px;
                line-height: 45px;
                border-radius: 10px 0;
                @include mw($md) {
                    font-size: 18px;
                    letter-spacing: 1px;
                }
            }
            &.bigger{
                font-size: 22px;
                letter-spacing: 2px;
                height: 48px;
                line-height: 48px;
                border-radius: 10px 0;
                @include mw($md) {
                    font-size: 18px;
                    letter-spacing: 1px;
                }
            }
            &.arrow{
                position: relative;
                padding-right: 58px;
                @include mw($sm) {
                    padding-right: 28px;
                }
                &:before,
                &:after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 11px;
                    height: 18px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    right: 27px;
                    transition: opacity .3s;
                    margin: auto;
                    @include mw($sm) {
                        right: 7px;
                    }
                }
                &:before{
                    background-image: url(../images/larrowwhite.png);
                    opacity: 1;
                }
                &:after{
                    background-image: url(../images/larrowcolor.png);
                    opacity: 0;
                }
                &.red{
                    &:after{
                        background-image: url(../images/larrowcolorred.png);
                    }
                }
                &:hover{
                    &:before{
                        opacity: 0;
                    }
                    &:after{
                        opacity: 1;
                    }
                }
            }
            &.download{
                position: relative;
                padding-left: 50px;
                padding-right: 30px;
                @include mw($sm) {
                    padding-left: 40px;
                    padding-right: 20px;
                }
                &:before,
                &:after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 18px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    left: 17px;
                    transition: opacity .3s;
                    margin: auto;
                    @include mw($sm) {
                        left: 7px;
                    }
                }

                &:before{
                    background-image: url(../images/download.svg);
                    opacity: 1;
                }
                &:after{
                    background-image: url(../images/downloadblue.svg);
                    opacity: 0;
                }
                &:hover{
                    &:before{
                        opacity: 0;
                    }
                    &:after{
                        opacity: 1;
                    }
                }
            }
        }
        &.invert {
            > button,
            > a{ 
                background-color: $white;
                color: $blue;

                &:hover{
                    color: $white;
                    background-color: $blue;
                }
            }
        }
    }
    &__form{
        position: relative;
        ::-webkit-input-placeholder{ color: rgba(#2c2745, 1); }
        :-ms-input-placeholder { color: rgba(#2c2745, 1); }
        ::-moz-placeholder { color: rgba(#2c2745, 1); opacity: 1; }
        :-moz-placeholder { color: rgba(#2c2745, 1); opacity: 1; }
        label{
            color: #2c2745;
            font-size: 16px;
            font-weight: 400;
            line-height: 35px;
        }
        .input{
            position: relative;
            padding-bottom: 15px;
            input{
                position: relative;
                color: $blue;
                z-index: 2;
                width: 100%;
                height: 36px;
                border: 0;
                border-bottom: 1px solid #d7d7d7;
                font-size: 16px;
                font-weight: 400;
                line-height: 34px;
                background-color: transparent;
                &.error{
                    border-color: red;
                }
            }
        }
        .textarea{
            padding-bottom: 15px;
            textarea{
                padding: 0 20px;
                width: 100%;
                height: 154px;
                border-radius: 2px 20px;
                border: 1px solid #d7d7d7;
                background-color: $white;
                line-height: 20px;
            }
            &.error textarea{
                border-color: red;
            }
        }
        .checkbox{
            padding-bottom: 25px;
            label{
                display: block;
                position: relative;
                input[type='radio'],
                input[type='checkbox']{
                    display: none;
                }
                > span{
                    display: block;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 16px;
                    padding-left: 24px;
                    color: $navyblue;
                    padding-top: 0;
                    a{
                        text-decoration: underline;
                    }
                } 
                > span:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin: auto;
                    display: inline-block;
                    vertical-align: top;
                    width: 15px;
                    height: 15px;
                    border: 2px solid $blue;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                input[type='radio']:checked + span:before,
                input[type='checkbox']:checked + span:before{
                   background-image: url(../images/checkbox.jpg);   
                }
                .info{
                    position: absolute;
                    margin: auto;
                    bottom: 18px;
                    left: 0;
                    box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.06);
                    border: 1px solid #e1e1e1;
                    background-color: #ffffff;
                    display: none;
                    z-index: 2;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 18px;
                    padding: 30px;
                }
                .show:hover + .info{
                    display: block;
                }
                &.error{
                    > span:before{
                        border-color: red;
                    }
                }
            }
        }
        .button{
            text-align: right;
            @include mw($sm) {
                text-align: left;
                padding-top: 20px;
            }
            button{
                max-width: 100%;
                width: 244px;
            }
        }
        .note{
            padding-bottom: 25px;
            color: #2c2745;
            font-size: 11px;
            font-style: italic;
            line-height: 15px;
        }
        .required{
            color: #2c2745;
            font-size: 11px;
            font-weight: 400;
            line-height: 18px;
        }
        .response{
            padding-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 46px;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 13px;
            line-height: 15px;
            &.error{
                color: #ff6666;
            }
            &.success{
                color: #66b266;
            }
        }
    }
}
.chocolat-pagination{
    color: $white !important;
}

.cookie{
    position: fixed;
    z-index: 12;
    margin: auto;
    bottom: 30px;
    left: 0;
    right: 0;
    @media (max-width: 767px) {
        bottom: 10px;
    }
    &__content{
        width: 660px;
        box-shadow: -5px 9px 13px rgba(0, 0, 0, 0.08);
        border: 1px solid #d6d6d6;
        background-color: #ffffff;
        margin: 0 auto;
        padding: 40px 40px 30px;
        max-width: 100%;
        @media (max-width: 767px) {
            padding: 10px;
            max-height: 80vh;
            overflow-y: scroll;
        }
        .read{
            color: #414141;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
        .buttons{
            padding-top: 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 10px 0;
                color: #ffffff;
                font-family: 'Bebas Neue', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.35px;
                min-width: 132px;
                height: 24px;
                padding: 0 20px;
                text-align: center;
                border-width: 1px;
                border-style: solid;
                transition: color .3s, background-color .3s;
                @include mw($sm) {
                    padding: 0 3px;
                    height: 40px;
                    min-width: inherit;
                    width: 49%;
                }
                &:nth-child(1){
                    background-color: #1197cd;
                    border-color: #1197cd;
                }
                &:nth-child(2){
                    background-color: #828282;
                    border-color: #828282;
                }
                &:hover{
                    background-color: #fff !important;
                    color: #000 !important;
                }
            }
        }
    }
}


.form-pop{
    position: fixed;
    z-index: 12;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.8);
    display: none;
    &.show{
        display: block;
    }
    &__content{
        width: 660px;
        box-shadow: -5px 9px 13px rgba(0, 0, 0, 0.08);
        border: 1px solid #d6d6d6;
        background-color: #ffffff;
        margin: 0 auto;
        padding: 50px 40px 20px;

        max-width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

  
        @include mw($sm) {
            padding: 20px 10px 10px;
            max-height: 80vh;
            overflow-y: scroll;
        }
        .read{
            text-align: center;
            color: #414141;
            font-family: 'Open Sans', sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            @include mw($sm) {
                br{
                    display: none;
                }
            }
            p.big{
                font-family: $fontBig;
                font-size: 28px;
                font-weight: 700;
                line-height: 30px;
                color: #062f5f;
                margin-bottom: 8px;
            }
        }
        .buttons{
            padding-top: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            @include mw($sm) {
                justify-content: space-between;
            }
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 10px 20px;
                color: #ffffff;
                font-family: 'Bebas Neue', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.35px;
                min-width: 132px;
                width: 200px;
                height: 24px;
                padding: 0 20px;
                text-align: center;
                border-width: 1px;
                border-style: solid;
                transition: color .3s, background-color .3s;
                @include mw($sm) {
                    margin-left: 0;
                    margin-right: 0;
                    padding: 0 3px;
                    height: 40px;
                    min-width: inherit;
                    width: 49%;
                }
                &:nth-child(1){
                    background-color: #1197cd;
                    border-color: #1197cd;
                }
                &:nth-child(2){
                    background-color: #828282;
                    border-color: #828282;
                }
                &:hover{
                    background-color: #fff !important;
                    color: #000 !important;
                }
            }
        }
    }
}

.slider__slide .open-video-js {
    display: block;
    font-size: 0;
    position: relative;


    > span{
        position: absolute;
        display: block;
        text-align: center;
        font-size: 30px;
        line-height: 32px;
        left: 0;
        right: 0;
        top: 50%;
        font-family: $fontBig;
        transform: translateY(-50%);
        padding: 0 15px;
        color: $white;

    }
}
div.altumcode-cookie-notification-widget {
    bottom: 60px !important;
    width: 40px !important;
    height: 40px !important;
}

// .slider__slide .open-video-js:after {
//     content: '';
//     display: inline-block;
//     background-image: url(/assets/images/play.png);
//     background-position: center;
//     background-repeat: no-repeat;
//     position: absolute;
//     z-index: 5;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
// }

// .slider__slide .open-video-js img {
//     opacity: 0.80;
// }