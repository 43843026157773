.sub-more{
    padding-top: 60px;
    @include mw($sm) {
        padding-top: 40px;
    }
    &__content{
        .item{
            padding: 20px 0;
            &__image{
                border-radius: 30px 0;
                border: 1px solid #dedede;
                background-color: $white;
                overflow: hidden;
                img{
                    width: 100%;
                    vertical-align: top;
                    transition: transform .3s;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
            }
            &__title{
                padding-top: 10px;
                color: $navyblue;
                font-size: 14px;
                font-weight: 700;
                line-height: 18px;
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                }
                span{
                    display: block;
                    font-weight: 400;
                    color: #737373;
                }
            }
        }
    }
}