.sub-gallery{
    padding-top: 100px;
    @include mw($sm) {
        padding-top: 40px;
    }
    .main-gallery-month{
        width: 100%;
        text-align: center;
    }
    .g__title{
        text-align: center;
        padding-bottom: 0;
        .menu{
            padding-top: 40px;
            padding-bottom: 20px;
        }
    }
    &__content{
        position: relative;
        .leaf{
            &--1{
                right: -303px;
                top: -360px;
            }
        }
        .tab-relative{
            position: relative;
        }
        .tab{
            position: absolute;
            z-index: -1;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            opacity: 0;
            transition: opacity .4s;
            &.active{
                position: static;
                opacity: 1;
                z-index: 1;
            }
        }
        .submenu{
            padding-bottom: 20px;
            ul{
                display: flex;
                justify-content: center;
                li{
                    position: relative;
                    margin: 0 20px;
                    color: $blue;
                    font-family: $fontBig;
                    font-size: 38px;
                    line-height: 38px;
                    text-align: center;
                    cursor: pointer;
                    @include mw($sm) {
                        font-size: 24px;
                        line-height: 24px;
                        margin: 0 10px;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        margin: auto;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 2px;
                        width: 100%;
                        background-color: transparent;
                        -webkit-transition: background-color .3s;
                        transition: background-color .3s;
                    }
                    &.active:after {
                        background-color: #1197cd;
                    }
                }
            }
        }
        .gallery-month{
            padding: 0 20px;
            padding-bottom: 20px;
            padding-top: 40px;
            text-align: center;
            color: $greenOther;
            font-family: $fontBig;
            font-size: 34px;
            line-height: 34px;
            text-align: center;
            p{
                width: 100%;
            }
            @include mw($sm) {
                font-size: 28px;
                line-height: 30px;
            }
        }
        .slider{
            margin: 0 -20px;
            &__slide{
                // padding: 0 20px;
                padding: 20px;
                width: 25%;
                @include mw($lg) {
                    width: 33.33%;
                }
                @include mw($sm) {
                    width: 50%;
                }
                @include mw($xs) {
                    width: 100%;
                }
                img{
                    vertical-align: top;
                    width: 100%;
                    border-radius: 15px 0;
                }
            }
            > div{
                display: flex;
                flex-wrap: wrap;
            }
            .slick-arrow{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                font-size: 0 !important;
                background-color: transparent !important;
                border: 0 !important;
                width: 24px;
                height: 38px;
                display: block;
                margin: 0 !important;
                padding: 0 !important;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                @include mw($xl) {
                    top: auto;
                    bottom: 0;
                    transform: translateY(55px);
                }  
                &.slick-disabled{
                    display: none !important;
                }
                &.slick-prev{
                    left: -42px;
                    background-image: url(../images/galleryleft.png);
                    @include mw($xl) {
                        left: 10%;
                    }
                    @include mw($sm) {
                        left: 21%;
                    }  
                } 
                &.slick-next{
                    right: -42px;
                    background-image: url(../images/galleryright.png);
                    @include mw($xl) {
                        right: 10%;
                    }  
                    @include mw($sm) {
                        right: 21%;
                    }  
                } 
            }      
        }
        
    }
    &__link{
        padding-top: 80px;
        padding-bottom: 50px;
        text-align: center;
        @include mw($sm) {
            padding-bottom: 0;
        }
    }
    &--page{
        padding-top: 0;
        .g__title{
            padding-top: 40px;
            padding-bottom: 40px;
            .menu{
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        > div{
            &:first-child{
                .g__title{
                    @include mw($sm) {
                        padding-top: 40px;
                    }
                }
            }
            .g__leaf{
                display: none;
            }
            &:nth-child(2){
                .g__leaf{
                    display: block;
                }
            }
        }
    }
    .mobile-videos{
        display: none;
        @include mw($sm) {
            display: block;
        }
    }
    .hide-on-mobiles{
        @include mw($sm) {
            display: none;
        }  
    }
}