*{
    outline: 0;
}
html, * {
    font-family: sans-serif; 
    -ms-text-size-adjust: none; 
    -webkit-text-size-adjust: none; 
}
body {
    margin: 0; 
    padding: 0;
    overflow: hidden;
}
.hamburger-box{
    display: block !important;
}
input, 
textarea,
select {
    -webkit-appearance: none; 
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: 0px; 
    outline: 0;
}
select::-ms-expand { 
    display: none; 
}
textarea {
    resize: none;
}
.hide-section-title{
    position: absolute; 
    left: -10000px; 
    visibility: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
b,
strong,
ul,
li,
ol,
br,
i,
u,
a:hover,
a:focus,
a:visited{ 
    font: inherit; 
    color: inherit;
    margin: 0; 
    padding: 0;
    text-decoration: none; 
    outline: 0;
}
sup{
    color: inherit;
    font-weight: inherit;
}
b, 
strong{
    font-weight: 600;
}
.text{
    p, ul, ol{
        display: block;
        padding: 8px 0;
    }
    ul, ol{
        margin-left: 18px;
        li{
            padding: 6px 0;
        }
        li:before {
            content: '.';
            display: inline-block;
            vertical-align: top;
            background-image: url(../images/li.png);
            background-position: center left;
            background-repeat: no-repeat;
            line-height: inherit;
            width: 18px;
            margin-left: -18px;
            font-size: 0;
        }
    }
    &--whiteli{
        ul{
            list-style: disc;
            background-image: none !important;
        }
    }
}
.hide{
    display: none;
}