.home-info{
    position: relative;
    .icon{
        padding-top: 15px;
        text-align: center;
        width: 166px;
        height: 169px;
        border: 1px solid #c8c8c8;
        background-color: $white;
        border-radius: 42px 0;
        position: absolute;
        margin: auto;
        z-index: 4;
        &--1{
            top: -118px;
            right: 20px;
            @include mw($lg) {
                top: -188px;
            }
            @include mw($sm) {
                position: relative;
                top: 0;
                right: 0;
                margin-bottom: 40px;
            }
        }
        &--2{
            left: 20px;
            bottom: -136px;
            @include mw($lg) {
                left: 60px;
            }
            @include mw($sm) {
                position: relative;
                left: 0;
                bottom: 0;
                margin-top: 40px;
                margin-bottom: 40px;
            }
        }
        &--3{
            bottom: 390px;
            right: -85px;
            @include mw($lg) {
                bottom: 160px;
                right: 0;
            }
            @include mw($sm) {
                position: relative;
                top: 0;
                right: 0;
                margin-top: 40px;
                margin-bottom: 40px;
            }
        }
        &--4{
            margin-top: 146px;
            position: static;
            @include mw($sm) {
                margin-top: 40px;
                margin-bottom: 20px;
            }
        }
        &--5{
            margin-top: 195px;
            position: static;
            @include mw($sm) {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
        .image{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            height: 80px;
            &--move{
                margin-left: 10px;
            }
            img{
                vertical-align: top;
            }
        }
        h1,h2,h3,h4,h5,h6,p{
            padding-top: 7px;
            display: block;
            color: #284b75;
            font-family: $fontBig;
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
        }
    }
    &__content{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &--top{
            justify-content: flex-start;
        }
        @include mw($sm) {
            &--mobpad{
                padding-top: 20px;
            }
        }
        .title{
            position: relative;
            z-index: 2;
            color: $blue;
            font-family: $fontBig;
            font-size: 44px;
            line-height: 46px;
            @include mw($sm) {
                font-size: 40px;
                line-height: 42px;
                br{
                    display: none;
                }
                &--mobpad{
                    padding-top: 40px;
                }
            }
            span{
                color: $navyblue;
            }
        }
        .text{
            position: relative;
            z-index: 2;
            padding-top: 20px;
            padding-bottom: 10px;
            strong{
                font-weight: 700;
            }
        }
        .link{
            padding-top: 15px;
            position: relative;
            z-index: 2;
            &--lesspad{
                padding-top: 5px;
            }
        }
        .leaf{
            position: absolute;
            margin: auto;
            z-index: 1;
            width: 839px;
            height: 610px;
            border-radius: 80px 0;
            border: 1px solid #e0e0e0;
            background-color: $white;
            @include mw($sm) {
                display: none;
            }
        }
    }
    &__title{
        padding-bottom: 60px;
        @include mw($sm) {
            padding-bottom: 40px;
        }
        .title{
            position: relative;
            z-index: 2;
            color: $blue;
            font-family: $fontBig;
            font-size: 44px;
            line-height: 46px;
            @include mw($sm) {
                font-size: 40px;
                line-height: 42px;
                br{
                    display: none;
                }
                &--mobpad{
                    padding-top: 40px;
                }
            }
            &--center{
                text-align: center;
            }
            span{
                color: $navyblue;
            }
        }
    }
    &__image{
        position: relative;
        z-index: 2;
        &--pad{
            padding-top: 10px;
        }
        @include mw($sm) {
            &--mobpad{
                padding-top: 20px;
            }
        }
        img{
            width: 100%;
            vertical-align: top;
            border-radius: 30px 0
        }
    }
    &__top{
        position: relative;
        padding-top: 100px;
        padding-bottom: 60px;
        background-image: url(../images/birds.png);
        background-repeat: no-repeat;
        background-position: top -90px right -267px;
        @include mw($sm) {
            padding-top: 40px;
            padding-bottom: 0;
        }
        .home-info__content{
            .leaf{
                bottom: -60px;
                right: -123px;
            }
        }
    }   
    &__middle{
        position: relative;
        padding-top: 146px;
        padding-bottom: 88px;
        background-image: url(../images/park.png);
        background-repeat: no-repeat;
        background-position: bottom 75px left -246px;
        @include mw($sm) {
            padding-top: 40px;
            padding-bottom: 0;
        }
        .home-info__content{
            padding-right: 50px;
            @include mw($sm) {
                padding-right: 0;
            }
            .leaf{
                top: -60px;
                left: -84px;
            }
        } 
    }
    &__bottom{
        position: relative;
        padding-top: 87px;
        @include mw($sm) {
            padding-top: 40px;
        }
        .home-info__content{
            .text--pad{
                padding-right: 110px;
            }
            .leaf{
                top: -306px;
                right: -84px;
                @include mw($lg) {
                    top: -396px;
                    right: -4px;
                }
            }
        } 
    }
    &__background{
        @include margin-top(-58px);
        padding-bottom: 56px;
        @include mw($sm) {
            margin-top: 0;
            padding-bottom: 0;
        }
        .imagebg{
            padding-left: 20px;
            @include mw($sm) {
                padding-left: 0;
            }
            img{
                vertical-align: top;
                max-width: inherit;
                @include mw($sm) {
                    width: 100%;
                }
            }
        }
        .link{
            padding-top: 25px;
            padding-left: 40px;
            @include mw($sm) {
                padding-top: 10px;
                padding-left: 0;
            }
        }
    }
    &__buttons{
        .link{
            padding: 10px 0;
            text-align: center;
        }
    }
}